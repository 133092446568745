// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Wrapper, ButtonWrapper } from './styles';

// Components
import { PrimaryButton, SecondaryButton, SubtleLink } from 'components/_ReactUI_V1';

// Types
import type { IntlType } from 'types';
import { NAVIGATION_ROUTES } from '../../utils/constants';

type Props = {
    userIsAdmin: boolean,
    circuitId: number;

    intl: IntlType,
    saveAsDisabled: boolean,
    saveDisabled: boolean,
    elevateDisabled: boolean,

    onSaveClicked: () => void,
    onSaveAsClicked: () => void,
    onElevateClicked: () => void,
};

class CircuitSetupFooter extends React.PureComponent<Props, null> {
    render() {
        return (
            <Wrapper>
                <ButtonWrapper>
                    {this.props.userIsAdmin && (
                        <>
                            <SubtleLink to={`${NAVIGATION_ROUTES.MINCHEM_V2}${NAVIGATION_ROUTES.CIRCUIT}${this.props.circuitId}/setup`}>
                                <SecondaryButton text={this.props.intl.formatMessage({
                                        id:
                                            'components.CircuitSetupFooter.editInMinChemV2Button',
                                    })} />
                            </SubtleLink>
                            <SecondaryButton
                                text={this.props.intl.formatMessage({
                                    id:
                                        'components.CircuitSetupFooter.elevateToSolvExtractCircuitButton',
                                })}
                                disabled={this.props.elevateDisabled}
                                onClick={this.props.onElevateClicked}
                            />
                        </>
                    )}
                    <SecondaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.CircuitSetupFooter.saveAsNewMinChemCircuitButton',
                        })}
                        disabled={this.props.saveAsDisabled}
                        onClick={this.props.onSaveAsClicked}
                    />
                    <PrimaryButton
                        text={this.props.intl.formatMessage({
                            id: 'components.CircuitSetupFooter.saveButton',
                        })}
                        disabled={this.props.saveDisabled}
                        onClick={this.props.onSaveClicked}
                    />
                </ButtonWrapper>
            </Wrapper>
        );
    }
}

export default injectIntl(CircuitSetupFooter);
