// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { MicroFrontEnds } from 'env';

import MicroFrontend from '..';
import { ContainerCentered, LayoutContainer } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';

import type { IntlType } from 'types';

import type { ImmutableUser } from 'services/Authentication/types';
import { selectUser } from 'services/Authentication/selectors';
import { isSolvayUser } from 'utils/authentication';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
};

type State = {
    isUnLoadable: boolean,
};

class SystemStatus extends React.PureComponent<Props, State> {
    state = {
        isUnLoadable: false,
    };

    static getHost = () => {
        return MicroFrontEnds.platformStatus; // name must match the secret object's structure in GCP
    };

    static getUniqueName = () => {
        return 'PlatformStatusMFE'; // name must match the container name in the MFE code
    };

    getMountProps = () => {
        return {
            locale: this.props.intl.locale.toUpperCase(),
        };
    };

    onError = () => {
        this.setState({ isUnLoadable: true });
    };

    render() {
        const isAdminOrSAM = isSolvayUser(this.props.user);
        if (!isAdminOrSAM) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: 'views.SystemStatusMFE.userMustBeAdminOrSAM',
                        })}
                    />
                </ContainerCentered>
            );
        }
        if (this.state.isUnLoadable) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: 'views.SystemStatusMFE.anErrorOccurredDuringLoad',
                        })}
                    />
                </ContainerCentered>
            );
        }
        return (
            <LayoutContainer>
                <MicroFrontend
                    name={SystemStatus.getUniqueName()}
                    host={SystemStatus.getHost()}
                    mountedMicroFrontendProps={this.getMountProps()}
                    onError={this.onError}
                    mfeIsInFullScreen
                />
            </LayoutContainer>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
    });

export default connect(mapStateToProps)(injectIntl(SystemStatus));
