// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import { SidebarLayout, SidebarTab } from 'components/_ReactUI_V1';

import MinChemSettings from 'components/UserSettings/MinChemSettings';
import SolvExtractSettings from 'components/UserSettings/SolvExtractSettings';
import UserNotificationGeneralSettings from 'components/UserSettings/UserNotificationGeneralSettings';

// Constants
import { STYLE_VALUES } from 'utils/constants';

// Helpers
import { isSolvExtractUser } from 'utils/authentication';

// Services
import { updateMinchemPreferences } from 'services/Authentication/thunks';
import {
    selectUser,
    selectMinchemPreferencesIsUpdating,
    selectErrors,
} from 'services/Authentication/selectors';
import {
    fetchNotificationSettings,
    updateNotificationSettings,
    fetchNotificationPlantSettings,
    updateNotificationPlantSettings,
} from 'services/Notifications/thunks';
import {
    selectNotificationSettingsAreFetching,
    selectNotificationSettingsAreUpdating,
    selectNotificationSettings,
    selectNotificationPlantSettingsAreFetching,
    selectNotificationPlantSettingsAreUpdating,
    selectNotificationPlantSettings,
} from 'services/Notifications/selectors';

// Types
import type { ErrorType, ReduxDispatch, UserPlantSettings, IntlType } from 'types';
import type { ImmutableUser, MinchemPreferences } from 'services/Authentication/types';
import type {
    ImmutableNotificationSettings,
    ImmutableNotificationPlantSettings,
} from 'services/Notifications/types';

// Local
export const USER_SETTINGS_TABS = {
    MINCHEM_SETTINGS: 'MINCHEM_SETTINGS',
    SOLVEXTRACT_SETTINGS: 'SOLVEXTRACT_SETTINGS',
    NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS',
};
export type UserSettingsTabsConstant = $Keys<typeof USER_SETTINGS_TABS>;

type Props = {
    intl: IntlType,
    user: ImmutableUser,

    errors: ErrorType,
    isUpdatingMinchemPreferences: boolean,
    updateMinchemPreferences: (id: number, settings: MinchemPreferences) => void,
};

type State = {
    activeTabKey: UserSettingsTabsConstant,
    notificationSettings: ImmutableNotificationSettings,
    notificationPlantSettings: ImmutableNotificationPlantSettings,
};

/**
 * The container that handles all the user settings logic
 */
class UserSettingsContainer extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        // Default sidebar option
        this.state = {
            activeTabKey: USER_SETTINGS_TABS.MINCHEM_SETTINGS,
            notificationSettings: this.props.notificationSettings,
        };
    }

    componentDidMount() {
        if (this.props.notificationSettings.isEmpty()) {
            this.props.fetchNotificationSettings(this.props.user.get('id'));
        }

        if (this.props.notificationPlantSettings.isEmpty()) {
            this.props.fetchNotificationPlantSettings(this.props.user.get('id'));
        }
    }

    /**
     * Set active tab through the sidebar
     */
    onSidebarToggle = (activeTabKey: UserSettingsTabsConstant) => () =>
        this.setState({ activeTabKey });

    saveMinchemPreferences = (newPreferences: MinchemPreferences) => {
        if (!this.props.user) {
            console.error('Received null user to update modal.');
            return;
        }
        this.props.updateMinchemPreferences(this.props.user.get('id'), newPreferences);
    };

    saveNotificationSettings = (
        notificationSettings: ?ImmutableNotificationSettings,
        notificationPlantSettings: ?ImmutableNotificationPlantSettings
    ) => {
        if (notificationSettings) {
            this.props.updateNotificationSettings(this.props.user.get('id'), notificationSettings);
        }

        if (notificationPlantSettings) {
            this.props.updateNotificationPlantSettings(
                this.props.user.get('id'),
                notificationPlantSettings
            );
        }
    };

    renderMainContent = () => {
        if (this.state.activeTabKey === USER_SETTINGS_TABS.MINCHEM_SETTINGS) {
            return (
                <MinChemSettings
                    user={this.props.user}
                    loading={this.props.isUpdatingMinchemPreferences}
                    onConfirm={this.saveMinchemPreferences}
                />
            );
        } else if (this.state.activeTabKey === USER_SETTINGS_TABS.SOLVEXTRACT_SETTINGS) {
            return (
                <SolvExtractSettings
                    loading={
                        this.props.isUpdatingNotificationSettings ||
                        this.props.isUpdatingNotificationPlantSettings
                    }
                    phoneNumberExists={Boolean(this.props.user.get('phoneNumber'))}
                    onConfirmNotificationSettings={this.saveNotificationSettings}
                />
            );
        } else if (this.state.activeTabKey === USER_SETTINGS_TABS.NOTIFICATION_SETTINGS) {
            return <UserNotificationGeneralSettings user={this.props.user} />;
        }
    };

    renderSidebar = () => (
        <React.Fragment>
            <SidebarTab
                handleOnHeaderClick={this.onSidebarToggle(USER_SETTINGS_TABS.MINCHEM_SETTINGS)}
                active={this.state.activeTabKey === USER_SETTINGS_TABS.MINCHEM_SETTINGS}
                title={this.props.intl.formatMessage({
                    id: 'containers.UserSettingsContainer.tabTitles.minchemSettings',
                })}
            />
            {isSolvExtractUser(this.props.user) && (
                <SidebarTab
                    handleOnHeaderClick={this.onSidebarToggle(
                        USER_SETTINGS_TABS.SOLVEXTRACT_SETTINGS
                    )}
                    active={this.state.activeTabKey === USER_SETTINGS_TABS.SOLVEXTRACT_SETTINGS}
                    title={this.props.intl.formatMessage({
                        id: 'containers.UserSettingsContainer.tabTitles.solvExtractSettings',
                    })}
                />
            )}
            <SidebarTab
                handleOnHeaderClick={this.onSidebarToggle(USER_SETTINGS_TABS.NOTIFICATION_SETTINGS)}
                active={this.state.activeTabKey === USER_SETTINGS_TABS.NOTIFICATION_SETTINGS}
                title={this.props.intl.formatMessage({
                    id: 'containers.UserSettingsContainer.tabTitles.notificationSettings',
                })}
            />
        </React.Fragment>
    );

    render() {
        return (
            <SidebarLayout
                styles={{
                    main: { overflowY: 'auto' },
                    container: {
                        height: `calc(100% - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },
                }}
                renderMain={this.renderMainContent}
                renderSidebar={this.renderSidebar}
                sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                collapsible
                mainFlush
                flush
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
    isUpdatingMinchemPreferences: selectMinchemPreferencesIsUpdating(),
    errors: selectErrors(),

    isFetchingNotificationSettings: selectNotificationSettingsAreFetching(),
    isUpdatingNotificationSettings: selectNotificationSettingsAreUpdating(),
    notificationSettings: selectNotificationSettings(),

    isFetchingNotificationPlantSettings: selectNotificationPlantSettingsAreFetching(),
    isUpdatingNotificationPlantSettings: selectNotificationPlantSettingsAreUpdating(),
    notificationPlantSettings: selectNotificationPlantSettings(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            updateMinchemPreferences,
            fetchNotificationSettings,
            updateNotificationSettings,
            fetchNotificationPlantSettings,
            updateNotificationPlantSettings,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(UserSettingsContainer))
);
