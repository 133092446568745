// @flow strict

import React from 'react';

// Types
import type { ReactNode, ChildrenType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    children: ChildrenType,
    user: ?ImmutableUser,
};

/**
 * UserIsReady can be used as a "middleware" to fetch disclaimers and ensure a user has accepted all disclaimers
 * Returns it's children with prop.userIsReady true if all disclaimers are accepted
 */
class UserIsReady extends React.PureComponent<Props> {
    // Clone children in order to pass prop.userIsReady based on user's acceptance of the disclaimers
    renderChildren = (children: ChildrenType) =>
        React.Children.map(children, (child: ReactNode) => {
            // Return early if provide child is falsey
            if (!child) {
                return null;
            }

            // Clone children to pass userIsReady prop down
            return React.cloneElement(child, {
                userIsReady: Boolean(
                    this.props.user &&
                        this.props.user.has('unacceptedDisclaimers') &&
                        this.props.user.get('unacceptedDisclaimers').isEmpty()
                ),
            });
        });

    render() {
        if (!this.props.children) {
            return null;
        }

        return this.renderChildren(this.props.children);
    }
}

export default UserIsReady;
