// @flow strict

import React from 'react';
// import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import firebase from 'firebase';

// Config
import { ToolTipGlobalStyles } from 'components/_ReactUI_V1';

import { firebaseConfig, gaTrackingId, PIWIK_PRO_API_URL, PIWIK_PRO_TRACKERID } from 'env';

// Constants
import { ROUTES } from 'utils/constants';

// Containers
import HeaderContainer from 'containers/HeaderContainer';
import ToastContainer from 'containers/ToastContainer';

// Components
import GlobalErrorBoundary from 'components/GlobalErrorBoundary';
import NonResponsivePageWarning from 'components/NonResponsivePageWarning';

// HOC
import UserIsReady from 'hoc/UserIsReady';
import RefreshFirebaseToken from 'hoc/RefreshFirebaseToken';
import Analytics from 'hoc/Analytics';

// Routes
import Routes from 'routes';

// Services
import { receivedFirebaseAuthSuccess } from 'services/Authentication/actions';
import { selectUser, selectUserIsFetching } from 'services/Authentication/selectors';
import { authenticateUser, whoami } from 'services/Authentication/thunks';
import { changeLocale } from 'services/Language/actions';
import { selectLocale } from 'services/Language/selectors';

// Styles
import GlobalStyle from 'styles/global';
import MobileApp from 'styles/mobile-app';
import { AppWrapper } from 'styles/common';

import 'sanitize.css/sanitize.css';

// Types
import type { ReduxDispatch, InputEvent, LocationType, RouteType } from 'types';
import type { ImmutableUser, Whoami } from 'services/Authentication/types';

// Utils
import { appLocales } from 'i18n';
import { getFirebaseAuth, getUsersLanguage } from 'utils/authentication';

import PiwikPro from '@piwikpro/react-piwik-pro';

if (process?.env?.NODE_ENV === 'production') {
    PiwikPro.initialize(PIWIK_PRO_TRACKERID, PIWIK_PRO_API_URL);
}

// Initialize Google Analytics
// if (gaTrackingId) {
//   ReactGA.initialize(gaTrackingId);
// }

type Props = {
    locale: string,
    location: LocationType,
    user?: ImmutableUser,
    userIsFetching: boolean,
    whoami: Whoami,
    authenticateUser: (user: ImmutableUser) => void,
    changeLocale: (event: InputEvent) => void,
    receivedFirebaseAuthSuccess: (user: ImmutableUser) => void,
};

type State = {
    initialized: boolean,

    closedNonResponsivePageWarning: boolean,
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

class App extends React.PureComponent<Props, State> {
    static defaultProps = {
        user: null,
    };

    state = {
        initialized: false,
    };

    componentDidMount() {
        if (process.env.HASH_SUFFIX) {
            console.info(`Application Version: ${process.env.HASH_SUFFIX}`);
        }
        // If a user api token exists we'll retrieve the user by dispatching
        // the whoami action and store the user in the global state
        this.unregisterAuthObserver = getFirebaseAuth().onAuthStateChanged(
            (user: ImmutableUser) => {
                // To clean the data structure
                const userParsed = JSON.parse(JSON.stringify(user));
                let userData = userParsed;

                if (userParsed) {
                    userData = {
                        ...userParsed.providerData[0],
                        firebaseUid: userParsed.uid,
                        photoUrl: userParsed.photoURL,
                        accessToken:
                            userParsed.stsTokenManager && userParsed.stsTokenManager.accessToken,
                    };

                    delete userData.photoURL;
                }

                this.props.receivedFirebaseAuthSuccess(userData);

                if (userData && this.state.initialized) {
                    // In this case, it means we have a successful login
                    this.props.authenticateUser(userData);
                } else if (userData) {
                    this.props.whoami();
                }

                this.setState({ initialized: true });
            }
        );
    }

    componentDidUpdate(prevProps: Props) {
        // If ui language is set handleChangeLocale
        if (
            this.props.user &&
            this.props.user !== prevProps.user &&
            this.props.user.getIn(['preferences', 'global', 'language'])
        ) {
            this.handleChangeLocale();
        }
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        if (this.unregisterAuthObserver) {
            this.unregisterAuthObserver();
        }
    }

    unregisterAuthObserver = null;

    handleChangeLocale = () => {
        const uiLanguage = getUsersLanguage(this.props.user).toLowerCase();

        // If ui language does not equal current locale, set new locale
        if (uiLanguage !== this.props.locale && appLocales.includes(uiLanguage)) {
            this.props.changeLocale({
                target: {
                    value: uiLanguage,
                },
            });
        } else if (!localStorage.getItem('language')) {
            localStorage.setItem('language', uiLanguage);
        }
    };

    handleOnCloseOfNonResponsivePageWarning = () =>
        this.setState({ closedNonResponsivePageWarning: true });

    /**
     * Find matching route, removing trailing slashes to be safe
     */
    getRoute = (): RouteType => {
        let currentPath = this.props.location.pathname;

        currentPath = currentPath === '/' ? currentPath : currentPath.replace(/\/$/, '');

        const route = Object.values(ROUTES).find((r: RouteType) =>
            Boolean(currentPath.match(r.match.replace(/\/$/, '')))
        );

        // If matching route was found, return
        if (route) {
            return route;
        }

        // If no route was found, return NOT_FOUND
        return ROUTES.NOT_FOUND;
    };

    render() {
        const route = this.getRoute();
        const routes = this.state.initialized && (
            <Routes user={this.props.user} userIsFetching={this.props.userIsFetching} />
        );
        // If our userIsFetching (displays global loader) or if the route is meant to be responsive
        const isResponsive = this.props.userIsFetching || (route && route.isPageResponsive);

        return (
            <React.Fragment>
                {route && !route.isPageResponsive && !this.state.closedNonResponsivePageWarning && (
                    <NonResponsivePageWarning
                        onClose={this.handleOnCloseOfNonResponsivePageWarning}
                    />
                )}
                {route && route.refreshUserTokenOnInterval && <RefreshFirebaseToken />}
                <AppWrapper isResponsive={isResponsive}>
                    <MobileApp />
                    <Helmet titleTemplate="%s - SolvExtract™️" defaultTitle="SolvExtract™️">
                        <meta name="description" content="SolvExtract™️" />
                    </Helmet>
                    <GlobalStyle />
                    <ToolTipGlobalStyles />
                    <GlobalErrorBoundary>
                        <UserIsReady user={this.props.user}>
                            <HeaderContainer
                                user={this.props.user}
                                userIsFetching={this.props.userIsFetching}
                                route={route}
                            />
                            {routes}
                        </UserIsReady>
                        <ToastContainer />
                    </GlobalErrorBoundary>
                </AppWrapper>
                {gaTrackingId && <Analytics />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
    user: selectUser(),
    userIsFetching: selectUserIsFetching(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            authenticateUser,
            changeLocale,
            receivedFirebaseAuthSuccess,
            whoami,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
