// @flow strict

/*
 *
 * Authentication reducer
 *
 */

import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';

// Actions
import {
    ACCEPT_DISCLAIMER_FAIL,
    ACCEPT_DISCLAIMER_SUCCESS,
    ACCEPT_DISCLAIMER_SAVING,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_SUCCESS,
    FIREBASE_AUTH_SUCCESS,
    LOGOUT_FAIL,
    LOGOUT_SUCCESS,
    USER_IS_FETCHING,
    WHOAMI_FAIL,
    WHOAMI_SUCCESS,
    IS_UPDATING_MINCHEM_PREFERENCES,
    UPDATE_MINCHEM_PREFERENCES_SUCCESS,
    UPDATE_MINCHEM_PREFERENCES_FAILURE,
    IS_GETTING_MINCHEM_PREFERENCES,
    GET_MINCHEM_PREFERENCES_SUCCESS,
    GET_MINCHEM_PREFERENCES_FAILURE,
    IS_UPDATING_USER_PREFERENCES,
    UPDATE_USER_PREFERENCES_SUCCESS,
    UPDATE_USER_PREFERENCES_FAILURE,
    PHONE_VERIFICATION_IS_LOADING,
    PHONE_VERIFICATION_START_SUCCESS,
    PHONE_VERIFICATION_START_FAILURE,
    PHONE_VERIFICATION_RESEND_SUCCESS,
    PHONE_VERIFICATION_RESEND_FAILURE,
    PHONE_VERIFICATION_CHECK_SUCCESS,
    PHONE_VERIFICATION_CHECK_FAILURE,
} from './actions';
import { PHONE_REMOVAL_SUCCESS } from '../Notifications/actions';
// Types
import type { GenericActionType } from 'types';

const initialState: ImmutableAuthenticationState = fromJS({
    acceptDisclaimerSaving: false,
    user: null,
    userIsFetching: false,
    isUpdatingMinchemPreferences: false,
    isGettingMinchemPreferences: false,
    phoneVerificationRequestId: '',
    phoneVerificationIsLoading: false,
    phoneVerificationErrors: {},
    errors: {},
});

function authenticationServiceReducer(
    state: ImmutableAuthenticationState = initialState,
    action: GenericActionType
) {
    switch (action.type) {
        case FIREBASE_AUTH_SUCCESS: {
            const {
                payload: { user },
            } = action;
            // If user, set api_token in local storage
            if (user) {
                localStorage.setItem('api_token', user.accessToken);
            }

            return state;
        }
        case ACCEPT_DISCLAIMER_SUCCESS:
        case AUTHENTICATION_SUCCESS:
        case WHOAMI_SUCCESS: {
            const {
                payload: { user },
            } = action;

            if (user) {
                // Delete accessToken before saving user to state
                delete user.accessToken;
                user.backendUser = true;
            }

            return state
                .set('user', fromJS(user))
                .set('userIsFetching', false)
                .set('acceptDisclaimerSaving', false)
                .set('errors', fromJS({}));
        }
        case ACCEPT_DISCLAIMER_FAIL:
        case AUTHENTICATION_FAIL:
        case WHOAMI_FAIL:
            return state
                .set('user', null)
                .set('userIsFetching', false)
                .set('acceptDisclaimerSaving', false)
                .set('errors', fromJS(action.payload.errors));
        case LOGOUT_SUCCESS:
            localStorage.removeItem('api_token');
            // On logout, clear start to avoid stale data
            return initialState;
        case LOGOUT_FAIL:
            localStorage.removeItem('api_token');
            return state.set('user', null).set('errors', fromJS(action.payload.errors));
        case LOCATION_CHANGE:
            // TODO: Look into why this was added; It's causing an issue where we loose our errors once we redirect
            // if (state.get('errors').size !== 0) {
            //     return state.set('errors', fromJS({}));
            // }
            return state;
        case USER_IS_FETCHING:
            return state.set('userIsFetching', action.payload.userIsFetching);
        case ACCEPT_DISCLAIMER_SAVING:
            return state.set('acceptDisclaimerSaving', action.payload.acceptDisclaimerSaving);
        case IS_UPDATING_MINCHEM_PREFERENCES:
            return state.set(
                'isUpdatingMinchemPreferences',
                action.payload.isUpdatingMinchemPreferences
            );
        case UPDATE_MINCHEM_PREFERENCES_SUCCESS: {
            const preferences = action.payload.preferences;

            return state
                .setIn(['user', 'preferences', 'minchem'], fromJS(preferences))
                .set('errors', fromJS({}))
                .set('isUpdatingMinchemPreferences', false);
        }
        case UPDATE_MINCHEM_PREFERENCES_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isUpdatingMinchemPreferences', false);
        case IS_GETTING_MINCHEM_PREFERENCES:
            return state.set(
                'isGettingMinchemPreferences',
                action.payload.isGettingMinchemPreferences
            );
        case GET_MINCHEM_PREFERENCES_SUCCESS: {
            const preferences = action.payload.preferences;

            return state
                .setIn(['user', 'preferences', 'minchem'], fromJS(preferences))
                .set('errors', fromJS({}))
                .set('isGettingMinchemPreferences', false);
        }
        case GET_MINCHEM_PREFERENCES_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isGettingMinchemPreferences', false);
        case IS_UPDATING_USER_PREFERENCES:
            return state.set('isUpdatingUserPreferences', action.payload.isUpdatingUserPreferences);
        case UPDATE_USER_PREFERENCES_SUCCESS:
            const preferences = action.payload.preferences;

            return state
                .setIn(['user', 'preferences', 'global'], fromJS(preferences))
                .set('errors', fromJS({}))
                .set('isUpdatingUserPreferences', false);
        case UPDATE_USER_PREFERENCES_FAILURE:
            return state
                .set('errors', fromJS(action.payload.errors))
                .set('isUpdatingUserPreferences', false);
        case PHONE_VERIFICATION_IS_LOADING:
            return state.set(
                'phoneVerificationIsLoading',
                action.payload.phoneVerificationIsLoading
            );
        case PHONE_VERIFICATION_START_SUCCESS:
            return state
                .set('phoneVerificationRequestId', action.payload.data.requestId)
                .set('phoneVerificationErrors', fromJS({}))
                .set('phoneVerificationIsLoading', false);
        case PHONE_VERIFICATION_START_FAILURE:
            return state
                .set('phoneVerificationErrors', fromJS(action.payload.errors))
                .set('phoneVerificationIsLoading', false);
        case PHONE_VERIFICATION_RESEND_SUCCESS:
            return state
                .set('phoneVerificationErrors', fromJS({}))
                .set('phoneVerificationIsLoading', false);
        case PHONE_VERIFICATION_RESEND_FAILURE:
            return state
                .set('phoneVerificationErrors', fromJS(action.payload.errors))
                .set('phoneVerificationIsLoading', false);
        case PHONE_VERIFICATION_CHECK_SUCCESS:
            return state
                .setIn(['user', 'phoneNumber'], action.payload.data.phoneNumber)
                .set('phoneVerificationErrors', fromJS({}))
                .set('phoneVerificationIsLoading', false);
        case PHONE_VERIFICATION_CHECK_FAILURE:
            return state
                .set('phoneVerificationErrors', fromJS(action.payload.errors))
                .set('phoneVerificationIsLoading', false);
        case PHONE_REMOVAL_SUCCESS:
            return state.setIn(['user', 'phoneNumber'], null);

        default:
            return state;
    }
}

export default authenticationServiceReducer;
