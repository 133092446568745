// @flow strict

import React from 'react';

// Assets
import loginBackground from 'assets/login-background.jpg';
import applicationLogo from 'assets/solvextract-logo.png';

// Config
import { appBlockingMessage } from 'env';

// Constants
import { APPLICATION_NAME } from 'utils/constants';

// Components
import { Common, Loader, Modal } from 'components/_ReactUI_V1';
import ModalContent from './ModalContent';

// Styles
import {
    BackgroundImage,
    LayoutContainer,
    LoaderContainer,
    MainBlock,
    StyledLogo,
    HiddenButton,
} from './styles';
import { ContainerCentered } from 'styles/common';

// Types
import type { ReactNode, ImmutableList } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';
import type { ImmutableDisclaimer } from 'services/Disclaimer/types';

type Props = {
    disclaimers?: ImmutableList<ImmutableDisclaimer>,
    isLoading: boolean,
    notAuthorizedMessage: string,
    onDisclaimerAcceptance: (id: number) => void,
    renderLoginUI: () => ReactNode,
    uiIsLoading: boolean,
    user?: ?ImmutableUser,
    userIsNotReadyMessage: ReactNode,
};

type State = {
    appBlockingMessage: string,
};

export default class Login extends React.Component<Props, State> {
    static defaultProps = {
        disclaimers: null,
        user: null,
    };

    state = {
        appBlockingMessage,
    };

    onAgreement = (id: number) => () => this.props.onDisclaimerAcceptance(id);

    handleDisableAppBlockingMessage = () =>
        this.setState({
            appBlockingMessage: '',
        });

    renderContent = () => {
        const loader = (
            <LoaderContainer>
                <Loader loading />
            </LoaderContainer>
        );

        if (this.state.appBlockingMessage) {
            return <div dangerouslySetInnerHTML={{ __html: this.state.appBlockingMessage }} />;
        }

        if (this.props.isLoading) {
            return loader;
        }

        // If no user and user is not being fetched, display login UI
        if (!this.props.user && !this.props.isLoading) {
            return this.props.renderLoginUI();
        }

        // If user and user has no role, display notAuthorizedMessage
        if (
            this.props.user &&
            this.props.user.has('roles') &&
            this.props.user.get('roles').size === 0
        ) {
            return this.props.notAuthorizedMessage;
        }

        // If user and not loading
        if (this.props.user && !this.props.isLoading) {
            // Set first disclaimer as active
            const disclaimer = this.props.disclaimers && this.props.disclaimers.get(0);
            if (disclaimer) {
                return (
                    <Modal
                        key={disclaimer.get('key')}
                        onHandleClose={null}
                        modalHeight="600px"
                        modalWidth="1088px"
                        overlay={false}
                        disableClose
                        hideCloseIcon
                        inlineTrigger
                    >
                        <ModalContent
                            actionButtonText={disclaimer.get('actionButtonText')}
                            disclaimerId={disclaimer.get('id')}
                            content={disclaimer.get('content')}
                            handleAgreement={this.onAgreement(disclaimer.get('id'))}
                            title={disclaimer.get('title')}
                            uiIsLoading={this.props.uiIsLoading}
                            warningNote={disclaimer.get('warningNote')}
                        />
                    </Modal>
                );
            } else {
                return this.props.userIsNotReadyMessage;
            }
        }

        return loader;
    };

    renderLogo = () => <StyledLogo src={applicationLogo} alt={APPLICATION_NAME} />;

    render() {
        return (
            <ContainerCentered>
                {this.state.appBlockingMessage && (
                    <HiddenButton onClick={this.handleDisableAppBlockingMessage} />
                )}
                <LayoutContainer>
                    <Common.Row alignItems="center" justifyContent="center">
                        <MainBlock>
                            {this.renderLogo()}
                            {this.renderContent()}
                        </MainBlock>
                    </Common.Row>
                </LayoutContainer>
                <BackgroundImage backgroundImage={loginBackground} />
            </ContainerCentered>
        );
    }
}
