// @flow strict

import type { ErrorsType, GenericActionType } from 'types';
import type {
    ImmutableUser,
    MinchemPreferences,
    GlobalUserPreferences,
    PhoneVerificationRequestId,
    PhoneCheckResponse,
} from './types';

export const ACCEPT_DISCLAIMER_FAIL = 'app/services/Authentication/ACCEPT_DISCLAIMER_FAIL';
export const ACCEPT_DISCLAIMER_SUCCESS = 'app/services/Authentication/ACCEPT_DISCLAIMER_SUCCESS';
export const ACCEPT_DISCLAIMER_SAVING = 'app/services/Authentication/ACCEPT_DISCLAIMER_SAVING';
export const AUTHENTICATION_FAIL = 'app/services/Authentication/AUTHENTICATION_FAIL';
export const AUTHENTICATION_SUCCESS = 'app/services/Authentication/AUTHENTICATION_SUCCESS';
export const FIREBASE_AUTH_SUCCESS = 'app/services/Authentication/FIREBASE_AUTH_SUCCESS';
export const LOGOUT_SUCCESS = 'app/services/Authentication/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'app/services/Authentication/LOGOUT_FAIL';
export const USER_IS_FETCHING = 'app/services/Authentication/USER_IS_FETCHING';
export const WHOAMI_SUCCESS = 'app/services/Authentication/WHOAMI_SUCCESS';
export const WHOAMI_FAIL = 'app/services/Authentication/WHOAMI_FAIL';

export const IS_UPDATING_MINCHEM_PREFERENCES =
    'app/managament/services/Authentication/IS_UPDATING_MINCHEM_PREFERENCES';
export const UPDATE_MINCHEM_PREFERENCES_SUCCESS =
    'app/managament/services/Authentication/UPDATE_MINCHEM_PREFERENCES_SUCCESS';
export const UPDATE_MINCHEM_PREFERENCES_FAILURE =
    'app/managament/services/Authentication/UPDATE_MINCHEM_PREFERENCES_FAILURE';

export const IS_GETTING_MINCHEM_PREFERENCES =
    'app/managament/services/Authentication/IS_GETTING_MINCHEM_PREFERENCES';
export const GET_MINCHEM_PREFERENCES_SUCCESS =
    'app/managament/services/Authentication/GET_MINCHEM_PREFERENCES_SUCCESS';
export const GET_MINCHEM_PREFERENCES_FAILURE =
    'app/managament/services/Authentication/GET_MINCHEM_PREFERENCES_FAILURE';

export const IS_UPDATING_USER_PREFERENCES =
    'app/managament/services/Authentication/IS_UPDATING_USER_PREFERENCES';
export const UPDATE_USER_PREFERENCES_SUCCESS =
    'app/managament/services/Authentication/UPDATE_USER_PREFERENCES_SUCCESS';
export const UPDATE_USER_PREFERENCES_FAILURE =
    'app/managament/services/Authentication/UPDATE_USER_PREFERENCES_FAILURE';

export const PHONE_VERIFICATION_IS_LOADING =
    'app/services/Authentication/PHONE_VERIFICATION_IS_LOADING';

export const PHONE_VERIFICATION_START_SUCCESS =
    'app/services/Authentication/PHONE_VERIFICATION_START_SUCCESS';
export const PHONE_VERIFICATION_START_FAILURE =
    'app/services/Authentication/PHONE_VERIFICATION_START_FAILURE';

export const PHONE_VERIFICATION_RESEND_SUCCESS =
    'app/services/Authentication/PHONE_VERIFICATION_RESEND_SUCCESS';
export const PHONE_VERIFICATION_RESEND_FAILURE =
    'app/services/Authentication/PHONE_VERIFICATION_RESEND_FAILURE';

export const PHONE_VERIFICATION_CHECK_SUCCESS =
    'app/services/Authentication/PHONE_VERIFICATION_CHECK_SUCCESS';
export const PHONE_VERIFICATION_CHECK_FAILURE =
    'app/services/Authentication/PHONE_VERIFICATION_CHECK_FAILURE';

export const receivedAcceptDisclaimerFail = (errors: ErrorsType): GenericActionType => ({
    type: ACCEPT_DISCLAIMER_FAIL,
    payload: { errors },
});

export const receivedAcceptDisclaimerSuccess = (user: ImmutableUser): GenericActionType => ({
    type: ACCEPT_DISCLAIMER_SUCCESS,
    payload: { user },
});

export const setAcceptDisclaimerSaving = (acceptDisclaimerSaving: boolean = true) => ({
    type: ACCEPT_DISCLAIMER_SAVING,
    payload: { acceptDisclaimerSaving },
});

export const receivedAuthenticationFail = (errors: ErrorsType): GenericActionType => ({
    type: AUTHENTICATION_FAIL,
    payload: { errors },
});

export const receivedAuthenticationSuccess = (user: ImmutableUser): GenericActionType => ({
    type: AUTHENTICATION_SUCCESS,
    payload: { user },
});

export const receivedFirebaseAuthSuccess = (user: ImmutableUser): GenericActionType => ({
    type: FIREBASE_AUTH_SUCCESS,
    payload: { user },
});

export const receivedLogoutSuccess = (response: boolean): GenericActionType => ({
    type: LOGOUT_SUCCESS,
    payload: { response },
});

export const receivedLogoutFail = (errors: ErrorsType): GenericActionType => ({
    type: LOGOUT_FAIL,
    payload: { errors },
});

export const receivedWhoamiSuccess = (user: ImmutableUser): GenericActionType => ({
    type: WHOAMI_SUCCESS,
    payload: { user },
});

export const receivedWhoamiFail = (errors: ErrorsType): GenericActionType => ({
    type: WHOAMI_FAIL,
    payload: { errors },
});

export const setUserIsFetching = (userIsFetching: boolean = true) => ({
    type: USER_IS_FETCHING,
    payload: { userIsFetching },
});

export const setIsUpdatingMinchemPreferencesStatus = (
    isUpdatingMinchemPreferences: boolean = true
) => ({
    type: IS_UPDATING_MINCHEM_PREFERENCES,
    payload: { isUpdatingMinchemPreferences },
});

export const receivedUpdateMinchemPreferencesSuccess = (
    preferences: MinchemPreferences
): GenericActionType => ({
    type: UPDATE_MINCHEM_PREFERENCES_SUCCESS,
    payload: { preferences },
});

export const receivedUpdateMinchemPreferencesFailure = (errors: ErrorType): GenericActionType => ({
    type: UPDATE_MINCHEM_PREFERENCES_FAILURE,
    payload: { errors },
});

export const setIsGettingMinchemPreferencesStatus = (
    isGettingMinchemPreferences: boolean = true
) => ({
    type: IS_GETTING_MINCHEM_PREFERENCES,
    payload: { isGettingMinchemPreferences },
});

export const receivedGetMinchemPreferencesSuccess = (
    preferences: MinchemPreferences
): GenericActionType => ({
    type: GET_MINCHEM_PREFERENCES_SUCCESS,
    payload: { preferences },
});

export const receivedGetMinchemPreferencesFailure = (errors: ErrorType): GenericActionType => ({
    type: GET_MINCHEM_PREFERENCES_FAILURE,
    payload: { errors },
});

export const setIsUpdatingUserPreferencesStatus = (isUpdatingUserPreferences: boolean = true) => ({
    type: IS_UPDATING_USER_PREFERENCES,
    payload: { isUpdatingUserPreferences },
});
export const receivedUpdateUserPreferencesSuccess = (
    preferences: GlobalUserPreferences
): GenericActionType => ({
    type: UPDATE_USER_PREFERENCES_SUCCESS,
    payload: { preferences },
});

export const receivedUpdateUserPreferencesFailure = (errors: ErrorType): GenericActionType => ({
    type: UPDATE_USER_PREFERENCES_FAILURE,
    payload: { errors },
});

export const setPhoneVerificationIsLoading = (phoneVerificationIsLoading: boolean = true) => ({
    type: PHONE_VERIFICATION_IS_LOADING,
    payload: { phoneVerificationIsLoading },
});

export const receivedPhoneVerificationStartSuccess = (
    data: PhoneVerificationRequestId
): GenericActionType => ({
    type: PHONE_VERIFICATION_START_SUCCESS,
    payload: { data },
});

export const receivedPhoneVerificationStartFailure = (errors: ErrorType): GenericActionType => ({
    type: PHONE_VERIFICATION_START_FAILURE,
    payload: { errors },
});

export const receivedPhoneVerificationResendSuccess = (): GenericActionType => ({
    type: PHONE_VERIFICATION_RESEND_SUCCESS,
});

export const receivedPhoneVerificationResendFailure = (errors: ErrorType): GenericActionType => ({
    type: PHONE_VERIFICATION_RESEND_FAILURE,
    payload: { errors },
});

export const receivedPhoneVerificationCheckSuccess = (
    data: PhoneCheckResponse
): GenericActionType => ({
    type: PHONE_VERIFICATION_CHECK_SUCCESS,
    payload: { data },
});

export const receivedPhoneVerificationCheckFailure = (errors: ErrorType): GenericActionType => ({
    type: PHONE_VERIFICATION_CHECK_FAILURE,
    payload: { errors },
});
