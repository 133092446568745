// @flow strict

import React from 'react';
import firebase from 'firebase/app';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

// Assets
import microsoftLogo from 'assets/icons/microsoft-logo.svg';

// Constants
import { HELP_LINK } from 'utils/constants';

// Components
import Login from 'components/Login';

// Selectors
import {
    selectAcceptDisclaimerSaving,
    selectErrors,
    selectUser,
    selectUserIsFetching,
} from 'services/Authentication/selectors';

// Styles
import { colors } from 'styles/colors';

// Thunks
import { userAcceptDisclaimer } from 'services/Authentication/thunks';

// Types
import type { AuthenticateUser } from 'services/Authentication/types';
import type { IntlType, ReduxDispatch, ErrorType } from 'types';

// Utils
import { formatDisclaimers } from 'utils/helpers';
import { getFirebaseAuth, getUsersLanguage } from 'utils/authentication';

type Props = {
    acceptDisclaimerSaving: boolean,
    errors?: ErrorType,
    intl: IntlType,
    userAcceptDisclaimer: (id: number) => void,
    user: ?AuthenticateUser,
    userIsFetching: boolean,
};

export class LoginContainer extends React.PureComponent<Props> {
    static defaultProps = {
        errors: null,
    };

    uiConfig = {
        signInFlow: 'popup',
        // Once firebase returns with a successful auth, the router logic will kick in and redirect to /
        signInSuccessUrl: window.location.href,
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            {
                provider: 'microsoft.com',
                providerName: 'Microsoft',
                buttonColor: colors.white,
                iconUrl: microsoftLogo,
                loginHintKey: 'login_hint',
            },
        ],
    };

    handleDisclaimerAcceptance = (id: number) => {
        this.props.userAcceptDisclaimer(id);
    };

    renderLoginUI = () => (
        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={getFirebaseAuth()} />
    );

    render() {
        const usersDisclaimers = this.props.user && this.props.user.get('unacceptedDisclaimers');
        const disclaimers =
            usersDisclaimers &&
            formatDisclaimers(usersDisclaimers, this.props.intl, getUsersLanguage(this.props.user));
        return (
            <Login
                disclaimers={disclaimers}
                errors={this.props.errors}
                isLoading={this.props.userIsFetching}
                notAuthorizedMessage={this.props.intl.formatMessage({
                    id: 'containers.LoginContainer.notAuthorized',
                })}
                userIsNotReadyMessage={
                    <FormattedMessage
                        id="containers.LoginContainer.userIsNotReady"
                        values={{
                            link: (
                                <a href={HELP_LINK} target="_blank" rel="noopener noreferrer">
                                    {this.props.intl.formatMessage({
                                        id: 'containers.LoginContainer.userIsNotReady.link',
                                    })}
                                </a>
                            ),
                        }}
                    />
                }
                onDisclaimerAcceptance={this.handleDisclaimerAcceptance}
                renderLoginUI={this.renderLoginUI}
                uiIsLoading={this.props.acceptDisclaimerSaving}
                user={this.props.user}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectErrors(),
    user: selectUser(),
    userIsFetching: selectUserIsFetching(),
    acceptDisclaimerSaving: selectAcceptDisclaimerSaving(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            userAcceptDisclaimer,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(LoginContainer))
);
