// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import { MicroFrontEnds } from 'env';

import MicroFrontend from '..';
import { ContainerCentered, LayoutContainer } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';
import type { ImmutableUser } from 'services/Authentication/types';
import type { IntlType } from 'types';
import { selectUser } from 'services/Authentication/selectors';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
};

type State = {
    isUnLoadable: boolean,
};

class MinChemV2 extends React.PureComponent<Props, State> {
    state = {
        isUnLoadable: false,
    };

    static getHost = () => {
        return MicroFrontEnds.minChem; // name must match the secret object's structure in GCP
    };

    static getUniqueName = () => {
        return 'MinChemMFE'; // name must match the container name in the MFE code
    };

    getMountProps = () => {
        return {
            locale: this.props.intl.locale.toUpperCase(),
            userRoles: [...new Set(this.props.user.get('roles'))],
        };
    };

    onError = () => {
        this.setState({ isUnLoadable: true });
    };

    render() {
        if (this.state.isUnLoadable) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: `views.${MinChemV2.getUniqueName()}.anErrorOccurredDuringLoad`,
                        })}
                    />
                </ContainerCentered>
            );
        }
        return (
            <MicroFrontend
                name={MinChemV2.getUniqueName()}
                host={MinChemV2.getHost()}
                mountedMicroFrontendProps={this.getMountProps()}
                onError={this.onError}
                mfeIsInFullScreen
            />
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
    });

export default connect(mapStateToProps)(injectIntl(MinChemV2));
