// holds a list of APIs called outside of the ms-backend API
// TODO: DGM-3386 - move this back to env.js once the secrets are updated through terraform
export const APIs = {
    minchemPreferencesCF: {
        development: 'http://localhost:8095',
        dev: 'https://minchem-preferences-api-vbjptfqtfa-uk.a.run.app',
        staging: 'https://minchem-preferences-api-dxhaougkpa-uk.a.run.app',
        production: 'https://minchem-preferences-api-rnidpugijq-uk.a.run.app',
    }[process.env.NODE_ENV || 'development'],
    userPreferencesCF: {
        development: 'http://localhost:8098',
        dev: 'https://user-preferences-api-vbjptfqtfa-uk.a.run.app',
        staging: 'https://user-preferences-api-dxhaougkpa-uk.a.run.app',
        production: 'https://user-preferences-api-rnidpugijq-uk.a.run.app',
    }[process.env.NODE_ENV || 'development'],
}
