// @flow strict

import axios from 'axios';
import { push } from 'react-router-redux';

import { camelizeKeys, decamelizeKeys } from 'humps';

import { API_URL, API_VER } from 'env';

import { logoutUser } from 'services/Authentication/thunks';
import { store } from '../index';

import { legacyBackwardMappers } from './legacyMappers';

/**
 * Requests a URL, returning a promise.
 *
 * Axios by default will reject a promise if it is not between status codes 200-300
 * (This can be modified by implementing the validateStatus method)
 *
 * @param {string} url       The URL we want to request
 * @param {object} [options] The options we want to pass to "fetch"
 * @param {apiVer} [optional] Optional api version (eg. /api/v2/)
 * @param {humps} [optional] Set to false to not camelize/decamelize keys
 *
 * @return {object}           An object containing either "data" or "err"
 */

export default function request(
    url: string,
    options?: {
        method?: ?string,
        data?: ?Object,
        apiUrl?: ?string,
        requestWithXSolvayToken?: ?boolean,
    } = {
        method: 'GET',
        data: {},
        apiUrl: undefined,
        requestWithXSolvayToken: false,
    },
    params?: Object = {},
    apiVer?: string = API_VER,
    humps?: boolean = true,
    additionalParams?: Object = {}
) {
    const apiUrl = options.apiUrl || API_URL;
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem('api_token') || ''}`,
    };

    if (options.requestWithXSolvayToken) {
        headers['X-Solvay-Token'] = `Bearer ${localStorage.getItem('X-Solvay-Token') || ''}`;
    }

    return axios({
        url: `${apiUrl}${apiVer}${url}`,
        method: options.method ? options.method : 'GET',
        data: humps ? decamelizeKeys(options.data) : options.data,
        headers,
        params,
        ...additionalParams,
    })
        .then((response) => {
            const solvayToken = response.headers['x-solvay-token'];
            if (solvayToken) {
                localStorage.setItem('X-Solvay-Token', solvayToken);
            }
            return response;
        })
        .then((response: Object) =>
            Promise.resolve(humps ? camelizeKeys(response.data) : response.data)
        )
        .then(legacyBackwardMappers)
        .catch((error: Object) => {
            let returnError = {};
            if (error.response) {
                // We received a response from the server.
                if (error.response.status === 401 || error.response.status === 403) {
                    // The user is unauthorized or forbidden. Log them out.
                    store.dispatch(logoutUser());
                }
                if (error.response.data) {
                    // comes from our backend formatted errors.
                    returnError = error.response.data;
                } else {
                    returnError = {
                        error: 'request.generic_exception',
                        message: error.response.statusText,
                        extra: error,
                    };
                }
            } else if (error.request) {
                // We did not receive a response from the server, but we did a request.
                console.error('Generic error: Did not receive a server response.', error);
                returnError = {
                    error: 'request.empty_response',
                    message: 'Did not receive a server response.',
                    extra: error,
                };
            } else {
                // We weren't even able to make a request.
                console.error('Generic error: Could not make a request.', error);
                returnError = {
                    error: 'request.failed_request',
                    message: 'Could not make a request to the server.',
                    extra: error,
                };
            }

            return Promise.reject(humps ? camelizeKeys(returnError) : returnError);
        });
}

/**
 * Sends a request to the given URL, returning a promise. This function is specifically for cloud functions / apis other than ms-backend since the options are different.
 *
 * @param {string} url       The URL we want to request
 * @param {object} [options] The options we want to pass to "fetch"
 * @param {apiVer} [optional] Optional api version (eg. /api/v2/)
 * @param {humps} [optional] Set to false to not camelize/decamelize keys
 *
 * @return {object}           An object containing either "data" or "err"
 */
export function cloudFunctionApiRequest(
    url: string,
    options?: {
        method?: ?string,
        data?: ?Object,
        apiUrl: string, // required
    } = {
        method: 'GET',
        data: {},
        apiUrl, // required
    },
    params?: Object = {},
    apiVer?: string = API_VER,
    humps?: boolean = false, // do not camelize/decamelize keys for cloud functions
    additionalParams?: Object = {}
) {
    const _options = {
        ...options,
        requestWithXSolvayToken: true, // true by default for cloud functions
    };

    return request(url, _options, params, apiVer, humps, additionalParams);
}
