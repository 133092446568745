// @flow strict

import { createGlobalStyle } from 'styled-components';
import { LegacyTheme } from 'components/_ReactUI_V1';

import Roboto from 'assets/fonts/Roboto-Regular.ttf';

import { colors } from './colors';

const GlobalStyle = createGlobalStyle`
    // Define Roboto font
    @font-face {
        font-family: Roboto;
        src: url('${Roboto}') format('opentype');
    }

    html,
    body {
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        h1, h2, h3, h4, h5, h6 {
            font-weight: normal;

            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        sup {
            font-size: 65%;
        }
    }

    h1 {
        font-size: 20px;
        line-height: 24px;
    }

    h2 {
        color: ${LegacyTheme.defaultColor};
        font-size: 17px;
        line-height: 20px;
    }

    h3 {
        color: ${LegacyTheme.defaultColor};
        font-size: 15px;
        line-height: 18px;
    }

    h4 {
        color: ${LegacyTheme.defaultSubtleColor};
        font-size: 13px;
        line-height: 16px;
    }

    textarea,
    input,
    button,
    select,
    body.fontLoaded {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    #app {
        height: 100vh;
        overflow-y: hidden; // Allow for the application to do the vertical scrolling
    }

    // Firebase Style Overwrite
    #firebaseui_container {
        width: 100%;

        .firebaseui-idp-button[data-provider-id='microsoft.com'] {
            .firebaseui-idp-text {
                color: ${colors.grey75} !important;
            }
        }
    }
`;

export default GlobalStyle;
