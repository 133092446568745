// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

// Assets
import { ToolTipTrigger, SecondaryButton } from 'components/_ReactUI_V1';

// import solvayLogo from 'assets/solvay-logo-original.png';
// import solvayMobileLogo from 'assets/solvay-logo-mobile.png';
import HamburgerMenuIcon from 'assets/icons/hamburger-menu-icon';
import LanguageIcon from 'assets/icons/language-icon';

// Helpers
import {
    isAuthorized,
    isUserAuthorizedOnRoute
} from 'utils/authentication';

// Components

import PageTitle from './PageTitle/PageTitle';
import PageNavigator from './PageNavigator/PageNavigator';
import QuickNavigator from './QuickNavigator/QuickNavigator';

import LanguageModalContainer from 'containers/LanguageModalContainer';
import OverlayNavigation from 'components/Header/OverlayNavigation';
import NotificationsContainer from 'containers/NotificationsContainer';
import JiraRequestButton from './JiraRequestButton';

// Constants & Helpers
import { NAVIGATION_ROUTES, ROUTES, HELP_LINK } from 'utils/constants';

// Styles
import {
    Left,
    LogoContainer,
    LogoLink,
    MainNav,
    Right,
    SidebarNav,
    Wrapper,
    IconWrapper,
    HelpLink,
    DesktopLogo,
    MobileLogo,
} from './styles';

// Types
import type { IntlType, ImmutableList, RouteType } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';

type Props = {
    intl: IntlType,
    user?: ImmutableUser,
    userIsReady: boolean,
    userIsFetching: boolean,
    route: RouteType,
    currentLocation: string,

    onLogout: () => void,
    onLogoClick: () => void | null,
};

type State = {
    viewLanguageModal: boolean,
    viewOverlayNavigation: boolean,
};

class Header extends React.PureComponent<Props, State> {
    static defaultProps = {
        user: null,
    };

    state = {
        viewLanguageModal: false,
        viewOverlayNavigation: false,
    };

    componentDidUpdate(prevProps: Props) {
        if (
            (prevProps.user && !this.props.user) ||
            (!this.props.userIsReady && prevProps.userIsReady) ||
            this.props.currentLocation !== prevProps.currentLocation
        ) {
            this.setState({
                viewOverlayNavigation: false,
            });
        }
    }

    isLoginPage = () => Boolean(this.props.route.path.match(`^${NAVIGATION_ROUTES.LOGIN}`));

    hasPageNavigator = () => this.props.route.hasPageNavigator;

    hasPageTitle = () => this.props.route.hasTitle;

    handleLanguageClicked = () =>
        this.setState({
            viewLanguageModal: true,
        });

    handleHamburgerMenuClicked = () =>
        this.setState({
            viewOverlayNavigation: true,
        });

    handleCloseLanguageModal = () =>
        this.setState({
            viewLanguageModal: false,
        });

    handleCloseOverlayNavigation = () =>
        this.setState({
            viewOverlayNavigation: false,
        });

    /**
     * Fall back to simple help.
     */
    renderHelp = () =>
        HELP_LINK && (
            <HelpLink href={HELP_LINK} target="_blank" rel="noopener noreferrer">
                <ToolTipTrigger size={16} style={{ marginLeft: '15px' }} />
                <span style={{ marginLeft: '5px', marginRight: '15px' }}>
                    {this.props.intl.formatMessage({
                        id: 'components.Header.helpLink',
                    })}
                </span>
            </HelpLink>
        );

    /**
     * Renders action items for user if authenticated: language & hamburger menu
     * else: help and logout (if authenticated but not "ready")
     */
    renderActionItems = () => {
        if (this.props.userIsReady && this.props.user) {
            return (
                <React.Fragment>
                    <IconWrapper onClick={this.handleLanguageClicked}>
                        <LanguageIcon width={26} />
                    </IconWrapper>
                    <IconWrapper onClick={this.handleHamburgerMenuClicked} data-testid="burger-menu">
                        <HamburgerMenuIcon width={32} />
                    </IconWrapper>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {this.props.user && (
                        <SecondaryButton
                            onClick={this.props.onLogout}
                            text={this.props.intl.formatMessage({
                                id: 'components.Header.logout',
                            })}
                            style={{ width: '115px' }}
                            type="submit"
                        />
                    )}
                    {this.renderHelp()}
                </React.Fragment>
            );
        }
    };

    renderQuickNavigator = () => {
        return (
            <QuickNavigator route={this.props.route} currentLocation={this.props.currentLocation} />
        );
    };

    renderPageTitle = () => {
        if (!this.hasPageTitle()) {
            return <PageTitle route={{ name: null }} />;
        }
        return <PageTitle route={this.props.route} />;
    };

    renderPrimaryNavigation = () => {
        if (!this.hasPageNavigator()) {
            return null;
        }
        return (
            <PageNavigator route={this.props.route} currentLocation={this.props.currentLocation} />
        );
    };

    renderNotifications = (authorized: boolean) => {
        if (authorized) {
            return <NotificationsContainer />;
        }
    };



    renderLanguageModal = () =>
        this.state.viewLanguageModal && (
            <LanguageModalContainer onClose={this.handleCloseLanguageModal} />
        );

    render() {
        const authorized = this.props.userIsReady && isAuthorized(this.props.user);
        const currentPage = this.props.route;
        const isAuthorizedOnRoute =
            currentPage && isUserAuthorizedOnRoute(this.props.user, currentPage);
        const logoAlt = this.props.intl.formatMessage({
            id: 'components.Header.logoAlt',
        });

        // Hide SidebarNav right border until needed (renderQuickNavigator & renderActionItems renders)
        const displayDivider = !this.isLoginPage() && authorized && !this.props.userIsFetching;

        const overlayOpened = this.state.viewOverlayNavigation && this.props.user;

        const quickNavigator = authorized && isAuthorizedOnRoute && this.renderQuickNavigator();

        return (
            <Wrapper overlayOpened={overlayOpened}>
                {this.renderLanguageModal()}
                {overlayOpened && (
                    <OverlayNavigation
                        user={this.props.user}
                        page={currentPage && currentPage.name}
                        onClose={this.handleCloseOverlayNavigation}
                        onLogout={this.props.onLogout}
                    />
                )}
                <SidebarNav displayDivider={displayDivider} flexGrow={Boolean(quickNavigator)}>
                    {/* <LogoContainer>
                        <LogoLink onClick={this.props.onLogoClick} to="/">
                            <DesktopLogo src={solvayLogo} alt={logoAlt} />
                            <MobileLogo src={solvayMobileLogo} alt={logoAlt} />
                        </LogoLink>
                    </LogoContainer> */}
                    {quickNavigator}
                </SidebarNav>
                <MainNav>
                    <Left hideOnMobile={this.props.route.isPageResponsive} id='mainNav_leftSide'>
                        {authorized && isAuthorizedOnRoute && (
                            <>
                                {this.renderPageTitle()}
                                {this.renderPrimaryNavigation()}
                            </>
                        )}
                    </Left>
                    <Right>
                        <JiraRequestButton user={this.props.user}/>
                        {this.renderNotifications(authorized)}
                        {this.renderActionItems()}
                    </Right>
                </MainNav>
            </Wrapper>
        );
    }
}

export default injectIntl(Header);
