// @flow strict

import { replace } from 'react-router-redux';

import { report } from 'services/Errors/resources';
import {
    receivedAcceptDisclaimerSuccess,
    receivedAcceptDisclaimerFail,
    receivedAuthenticationSuccess,
    setAcceptDisclaimerSaving,
    receivedAuthenticationFail,
    receivedWhoamiSuccess,
    receivedWhoamiFail,
    receivedLogoutSuccess,
    receivedLogoutFail,
    setUserIsFetching,
    setIsUpdatingMinchemPreferencesStatus,
    receivedUpdateMinchemPreferencesSuccess,
    receivedUpdateMinchemPreferencesFailure,
    setIsGettingMinchemPreferencesStatus,
    receivedGetMinchemPreferencesSuccess,
    receivedGetMinchemPreferencesFailure,
    setIsUpdatingUserPreferencesStatus,
    receivedUpdateUserPreferencesSuccess,
    receivedUpdateUserPreferencesFailure,
    setPhoneVerificationIsLoading,
    receivedPhoneVerificationStartSuccess,
    receivedPhoneVerificationStartFailure,
    receivedPhoneVerificationResendSuccess,
    receivedPhoneVerificationResendFailure,
    receivedPhoneVerificationCheckSuccess,
    receivedPhoneVerificationCheckFailure,
} from './actions';

import { createUntranslatedFeedback } from 'services/Feedback/actions';
import {
    acceptDisclaimer,
    authenticate,
    logout,
    getAuthenticatedUser,
    updatePreferences,
    getMinchemPreferencesRequest,
    updateMinchemPreferencesRequest,
    startPhoneVerification,
    resendPhoneVerificationOTC,
    checkPhoneVerification,
} from './resources';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import type {
    ImmutableUser,
    UserPreferences,
    UserSettings,
    PhoneVerificationRequestId,
    PhoneCheckResponse,
} from 'services/Authentication/types';

import { getFirebaseAuth } from 'utils/authentication';

const LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY = 'X-Solvay-LastPageLocation';

const clearLocalStorage = () => {
    localStorage.removeItem('api_token');
    localStorage.removeItem('X-Solvay-Token');
    localStorage.removeItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY);
};

/**
 * Authenticates a user
 */
export const authenticateUser = (user: ImmutableUser) => (dispatch: ReduxDispatch) =>
    authenticate(user)
        .then((response: {}) => {
            dispatch(receivedAuthenticationSuccess(response));
            const lastPageLocation = localStorage.getItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY);
            if (lastPageLocation) {
                dispatch(replace(lastPageLocation));
            }
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedAuthenticationFail(error));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.authenticateUserFailed'));
        });

/**
 * Checks the user authentication token
 */
export const whoami = () => (dispatch: ReduxDispatch) => {
    dispatch(setUserIsFetching());
    getAuthenticatedUser()
        .then((response: {}) => {
            if (response) {
                dispatch(receivedWhoamiSuccess(response));
                const lastPageLocation = localStorage.getItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY);
                if (lastPageLocation) {
                    localStorage.removeItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY);
                    dispatch(replace(lastPageLocation));
                }
            } else {
                throw new Error('response is empty');
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedWhoamiFail(error));
            dispatch(replace('/'));
            dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.whoAmIFailed'));
        });
};

/**
 * Invalidates a user token, if clientOnly is true simply clear localStorage and redirect user to login view
 */
export const logoutUser = (clientOnly?: boolean = false) => (dispatch: ReduxDispatch) => {
    if (clientOnly) {
        clearLocalStorage();
        const currentLocation = window.location.pathname;
        localStorage.setItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY, currentLocation);
        dispatch(replace('/login'));
    } else {
        logout()
            .then((response: boolean) => {
                clearLocalStorage();
                const currentLocation = window.location.pathname;
                localStorage.setItem(LAST_PAGE_LOCATION_LOCAL_STORAGE_KEY, currentLocation);
                dispatch(receivedLogoutSuccess(response));
                getFirebaseAuth().signOut();
            })
            .catch((error: ResponseErrorType) => {
                report(error);
                dispatch(receivedLogoutFail(error));
                dispatch(createUntranslatedFeedback('ERROR', 'feedback.error.logoutUserFailed'));
            });
    }
};

export const userAcceptDisclaimer = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setAcceptDisclaimerSaving());
    acceptDisclaimer(id)
        .then((response: {}) => {
            if (response) {
                dispatch(receivedAcceptDisclaimerSuccess(response));
            } else {
                throw new Error('response is empty');
            }
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedAcceptDisclaimerFail(error));
        });
};

/**
 * Update the Minchem Preferences
 */
export const updateMinchemPreferences = (id: number, minchemPreferences: MinchemPreferences) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingMinchemPreferencesStatus());
    updateMinchemPreferencesRequest(id, minchemPreferences)
        .then((response: MinchemPreferences) => {
            dispatch(receivedUpdateMinchemPreferencesSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateMinchemPreferences'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateMinchemPreferencesFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateMinchemPreferencesFailed')
            );
        });
};

/**
 * Fetches the Minchem Preferences
 */
export const getMinchemPreferences = (id: number) => (dispatch: ReduxDispatch) => {
    dispatch(setIsGettingMinchemPreferencesStatus());
    getMinchemPreferencesRequest(id)
        .then((response: MinchemPreferences) => {
            dispatch(receivedGetMinchemPreferencesSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedGetMinchemPreferencesFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.getMinchemPreferencesFailed')
            );
        });
};

/**
 * Update the user preferences
 */
export const updateUserPreferences = (user: ImmutableUser, preferences: GlobalUserPreferences) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setIsUpdatingUserPreferencesStatus());
    updatePreferences(user.get('id'), preferences)
        .then((response: GlobalUserPreferences) => {
            dispatch(receivedUpdateUserPreferencesSuccess(response));
            dispatch(createUntranslatedFeedback('INFO', 'feedback.info.updateUserPreferences'));
        })
        .catch((error: ResponseErrorType) => {
            report(error);
            dispatch(receivedUpdateUserPreferencesFailure(error));
            dispatch(
                createUntranslatedFeedback('ERROR', 'feedback.error.updateUserPreferencesFailed')
            );
        });
};

/**
 * Start the user's phone verification
 */
export const startUserPhoneVerification = (userId: number, phoneNumber: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setPhoneVerificationIsLoading());
    startPhoneVerification(userId, phoneNumber)
        .then((response: PhoneVerificationRequestId) => {
            dispatch(receivedPhoneVerificationStartSuccess(response));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPhoneVerificationStartFailure(error));
        });
};

/**
 * Re-send the phone verification OTC
 */
export const resendUserPhoneVerificationOTC = (userId: number, requestId: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setPhoneVerificationIsLoading());
    resendPhoneVerificationOTC(userId, requestId)
        .then(() => {
            dispatch(receivedPhoneVerificationResendSuccess());
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPhoneVerificationResendFailure(error));
        });
};

/**
 * Validate the OTC and write the user's phone number
 */
export const checkUserPhoneVerification = (userId: number, requestId: string, otc: string) => (
    dispatch: ReduxDispatch
) => {
    dispatch(setPhoneVerificationIsLoading());
    checkPhoneVerification(userId, requestId, otc)
        .then((response: PhoneCheckResponse) => {
            dispatch(receivedPhoneVerificationCheckSuccess(response));
            dispatch(createUntranslatedFeedback('SUCCESS', 'feedback.success.phoneVerification'));
        })
        .catch((error: ResponseErrorType) => {
            dispatch(receivedPhoneVerificationCheckFailure(error));
        });
};
