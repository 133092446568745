// @flow

// The environment the app is running on. Locally this is: REACT_APP_ENV
export const appEnvironment = 'development';

// The API urls, deprecated in favor of the appEnvironment+frontend apis package
export const API_URL = 'https://ms-api-dot-dev-minchem-solvextract.appspot.com';
export const API_VER = '/api/';


// Frontend Micro-frontend Hosts:
export const MicroFrontEnds = {
    reportGenerationSystem: 'https://rgs-fe-dot-dev-minchem-solvextract.appspot.com',
    plantConfiguration: 'https://plant-configuration-mfe-dot-dev-minchem-solvextract.appspot.com',
    platformStatus: 'https://platform-status-mfe-dot-dev-minchem-solvextract.appspot.com',
    plantDashboard: 'https://plant-dashboard-mfe-dot-dev-minchem-solvextract.appspot.com',
    cyanex: 'https://cyanex-mfe-dot-dev-minchem-solvextract.appspot.com',
    reagentsManagement: 'https://reagents-management-mfe-dot-dev-minchem-solvextract.appspot.com',
    minChem: 'https://minchem-frontend-mfe-dot-dev-minchem-solvextract.appspot.com'
};

// User Authorization and Authentication:
export const firebaseConfig = {
    apiKey: 'AIzaSyB0Ut7iWbr3FUxzaCYX6j8gWdUW8-lf7AI',
    authDomain: 'dev-minchem-solvextract.firebaseapp.com',
    projectId: 'dev-minchem-solvextract',
};

// Google tracking:
export const gaTrackingId = '';

// Used to replace Login UI, for application blocking (must contain content to block application)
export const appBlockingMessage = '';


export const PIWIK_PRO_TRACKERID = '';
export const PIWIK_PRO_API_URL = '';
export const NAVBAR_DASHBOARD_V2_REDIRECT = true;