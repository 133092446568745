// @flow strict

import React from 'react';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { PrimaryButton } from 'components/_ReactUI_V1';

import { formatPhoneNumber } from 'utils/helpers';

// Styles
import { PhoneNumber } from './styles';
import { ContainerCentered } from 'styles/common';
// Components
import PhoneVerificationModalContainer from 'containers/PhoneVerificationModalContainer';
import ErrorMessage from 'components/ErrorMessage';

// Types
import type { IntlType, ErrorType, ReduxDispatch } from 'types';
import type { ImmutableUser } from 'services/Authentication/types';

// Services
import { removeUserPhoneNumber } from 'services/Notifications/thunks';
import { selectPhoneRemoveIsLoading, selectErrors } from 'services/Notifications/selectors';

type InjectedProps = {
    intl: IntlType,
};

type Props = InjectedProps & {
    user: ImmutableUser,
    isUpdating: boolean,
    errors: ErrorType,
    removeUserPhoneNumber: (userId: number) => void,
};

type State = {
    isOpen: boolean,
};

class UserPhone extends React.PureComponent<Props> {
    state = {
        isOpen: false,
    };

    getTranslation = (key: string, data: ?Object) =>
        this.props.intl.formatMessage(
            {
                id: `components.UserSettings.NotificationSettings.${key}`,
            },
            data
        );

    handleToggleModal = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    handleRemovePhoneClicked = () => {
        this.props.removeUserPhoneNumber(this.props.user.get('id'));
    };

    handleCustomErrors = (error: string) => {
        return `errorCodes.${error}`;
    };

    render() {
        const userPhoneNumber = this.props.user.get('phoneNumber');
        return (
            <React.Fragment>
                {userPhoneNumber && (
                    <div>
                        <PhoneNumber>{formatPhoneNumber(userPhoneNumber)}</PhoneNumber>
                        <PrimaryButton
                            onClick={this.handleToggleModal}
                            text={this.getTranslation('changePhoneNumber')}
                            style={{ marginLeft: '16px' }}
                        />
                        <PrimaryButton
                            loading={this.props.isUpdating}
                            onClick={this.handleRemovePhoneClicked}
                            text={this.getTranslation('removePhoneNumber')}
                            style={{ marginLeft: '16px' }}
                        />
                    </div>
                )}

                {!userPhoneNumber && (
                    <PrimaryButton
                        onClick={this.handleToggleModal}
                        text={this.getTranslation('addPhoneNumber')}
                        style={{ maxWidth: '180px' }}
                    />
                )}

                {this.state.isOpen && (
                    <PhoneVerificationModalContainer
                        userId={this.props.user.get('id')}
                        onClose={this.handleToggleModal}
                    />
                )}
                {this.props.errors.get('error') && (
                    <ContainerCentered>
                        <ErrorMessage
                            errorCode={this.handleCustomErrors(this.props.errors.get('error'))}
                            isRed
                            style={{ margin: 0, fontSize: 10 }}
                            isSmall={false}
                        />
                    </ContainerCentered>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = createStructuredSelector({
    isUpdating: selectPhoneRemoveIsLoading(),
    errors: selectErrors(),
});
const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            removeUserPhoneNumber,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(UserPhone));
