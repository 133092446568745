// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import * as cloneDeep from 'lodash/cloneDeep';
import { QuickNavigatorObservable } from 'components/_FrontendObservables';
import { SidebarLayout, OverflowEnd } from 'components/_ReactUI_V1';

import { MimicCircuit, SkipStream } from 'components/_McCabeThiele';

// Authentication

import { isSysAdmin, getLoginUserName } from 'utils/authentication';

// Components
import { HeaderOverflowContainer } from 'styles/common';
import PreventNavigationPrompt from 'components/PreventNavigationPrompt';
import CircuitSetupSidebar from 'components/CircuitSetupSidebar';
import CircuitSetupFooter from 'components/CircuitSetupFooter';
import MimicDiagram from 'components/MimicDiagram';
import CircuitDetailsModal from 'components/Modals/CircuitDetailsModal';

import AqueousStreamSelectModal from 'components/Modals/AqueousStreamSelectModal';
import PLSSkipStreamSelectModal from 'components/Modals/PLSSkipStreamSelectModal';
import ElectrolyteStreamSelectModal from 'components/Modals/ElectrolyteStreamSelectModal';
import OrganicInExtractStreamSelectModal from 'components/Modals/OrganicInExtractStreamSelectModal';
import OrganicInStripStreamSelectModal from 'components/Modals/OrganicInStripStreamSelectModal';

import ConfirmationModal from 'components/Modals/ConfirmationModal';
import AlertModal from 'components/Modals/AlertModal';
import MimicDiagramErrorBoundary from 'components/MimicDiagramErrorBoundary';

// Constants
import {
    STYLE_VALUES,
    UNIT_TYPES,
    PRODUCTION_UNIT_TYPES,
    STAGE_TYPES,
    STREAM_CIRCUITS,
    MAX_STAGE_COUNT,
    MIN_STAGE_COUNT,
    DIAGRAM_DISPLAY_MODES,
    STREAM_TYPES,
    PLS_STREAM_OPTIONS,
    ELECTROLYTE_STREAM_OPTIONS,
    ADVANCED_STREAMS_SETUP_TYPES,
    ORGANIC_IN_EXTRACT_STREAM_OPTIONS,
    NAVIGATION_ROUTES,
    DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR,
    NEW_TANK_OPTION,
    TOAST_DURATION,
    APPLICATION_TYPES,
} from 'utils/constants';

import { logUserAnalyticsInteraction } from 'utils/userAnalytics';

// Helpers
import { getStagesForStream, getStreamsForStage, getNextStage } from 'utils/helpers';

import {
    getStageById,
    getElectrolyteFeedToStage,
    getPlsBleedFromStage,
    getPlsSkipToStage,
    getOrganicBypassBleed,
} from 'components/MimicDiagram/helpers';

// Services
import { selectAllReagents, selectReagentsAreFetching } from 'services/Reagent/selectors';
import { selectAllOximes, selectOximesAreFetching } from 'services/Oxime/selectors';
import { selectAllMetals, selectMetalsAreFetching } from 'services/Metal/selectors';
import {
    selectMinChemCircuitQuery,
    selectMinChemIsQueryingStatus,
    selectCircuitIsFetchingStatus,
    selectCircuitsAreCreating,
    selectCircuitsAreUpdating,
    selectCircuitErrors,
} from 'services/Circuit/selectors';
import { selectUser } from 'services/Authentication/selectors';

// Thunks
import { newFeedback as createNewFeedback } from 'services/Feedback/thunks';
import { fetchAllReagents } from 'services/Reagent/thunks';
import { fetchAllMetals } from 'services/Metal/thunks';
import {
    fetchCircuit,
    createAdvancedCircuit,
    updateAdvancedCircuit,
} from 'services/Circuit/thunks';

// Types
import type {
    ReduxDispatch,
    ImmutableList,
    ErrorType,
    IntlType,
    ImmutableQueryStructure,
    HistoryType,
    UnitsConstant,
    ProductionUnitsConstant,
} from 'types';
import type { FeedbackType } from 'services/Feedback/types';
import type { ImmutableMetal } from 'services/Metal/types';
import type { ImmutableReagent } from 'services/Reagent/types';
import type { ImmutableOxime } from 'services/Oxime/types';
import type {
    LooseStage,
    LooseStream,
    RawStage,
    ImmutableStage,
    ImmutableCircuit,
    StageTypeConstant,
    AdvancedStreamTypeConstant,
    StreamSelectTypeConstant,
    OrganicInExtractOptionConstant,
    OrganicInStripOptionConstant,
    ElectrolyteOptionConstant,
    StageProperties,
} from 'services/Circuit/types';
import type { ImmutableUser } from 'services/Authentication/types';

export type OpenModalFunction = (streamData: LooseStream) => void;
export type ChangeStagePropertiesFunction = (
    stage: LooseStage,
    newProperties: StageProperties
) => void;

export const WASH_POSITIONS = {
    ETS: 'ETS',
    STE: 'STE',
};
export const WASH_POSITIONS_TO_LOCATION = {
    ETS: 1,
    STE: 2,
};

export type WashPosition = $Keys<typeof WASH_POSITIONS>;
type ModalTypes = 'STREAM_SELECT' | 'SAVE' | 'SAVEAS' | 'ELEVATE';
type ModalStates = LooseStream | Array<string>;

type Props = {
    history: HistoryType,
    intl: IntlType,

    circuitErrors: ErrorType,
    circuitId: number,

    minChemCircuitQuery: ImmutableQueryStructure<ImmutableCircuit>,
    loadingCircuit: boolean,

    fetchCircuit: (id: number) => void,
    fetchingSingleCircuit: boolean,

    isCreatingCircuit: boolean,
    createAdvancedCircuit: (circuit: ImmutableCircuit, redirect: boolean) => void,
    isUpdatingCircuit: boolean,
    updateAdvancedCircuit: (id: number, circuit: ImmutableCircuit) => void,

    loadingReagents: boolean,
    reagents: ImmutableList<ImmutableReagent>,
    loadingOximes: boolean,
    oximes: ImmutableList<ImmutableOxime>,
    fetchAllReagents: () => void,

    loadingMetals: boolean,
    metals: ImmutableList<ImmutableMetal>,
    fetchAllMetals: () => void,

    user: ImmutableUser,

    onElevateToSolvExtractCircuit: (circuitId: number) => void,

    createNewFeedback: (feedbackType: FeedbackType, message: string, duration?: number) => void,
};

/**
 * The circuit stages and streams state.
 */
type CircuitState = {
    stages: Array<LooseStage>,
    streams: Array<LooseStream>,
};

type State = CircuitState & {
    selectedCircuit: ?ImmutableCircuit,
    selectedReagent: ?ImmutableReagent,
    selectedOxime: ?ImmutableReagent,
    selectedMetal: ?ImmutableMetal,
    selectedCircuitUnit: UnitsConstant,
    selectedProductionUnit: ProductionUnitsConstant,
    isothermStoichiometryFactor: number,

    isModified: boolean, // the check when something in circuit setup has changed

    modal: ?{
        modalType: ModalTypes,
        modalState?: ModalStates,
    },
};

/**
 * This container holds the state for the setup page.
 * It handles all the logic related to the sidebar and the mimic diagram.
 */
class MimicDiagramContainer extends React.PureComponent<Props, State> {
    static EmptyState = {
        selectedCircuit: null,
        selectedReagent: null,
        selectedOxime: null,
        selectedMetal: null,
        selectedCircuitUnit: UNIT_TYPES.US,
        selectedProductionUnit: PRODUCTION_UNIT_TYPES.TONS_DAILY,
        isothermStoichiometryFactor: DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR,

        stages: [],
        streams: [],

        isModified: false,
        modal: null,
    };

    static getDerivedStateFromProps(nextProps: Props, prevState: State): State {
        if (!prevState) return prevState;

        const prevCircuit = prevState.selectedCircuit;
        const newCircuit = nextProps.minChemCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === nextProps.circuitId);
        const prevCircuitId = prevCircuit && prevCircuit.get('id');
        const newCircuitId = newCircuit && newCircuit.get('id');
        if (newCircuit && ((!prevCircuit && newCircuit) || prevCircuitId !== newCircuitId)) {
            const userDefaultStoiciometry =
                nextProps.user &&
                nextProps.user.getIn(
                    ['preferences', 'minchem', 'defaultIsothermStoichiometryFactor'],
                    DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR
                );
            return {
                selectedCircuit: newCircuit,
                selectedReagent: nextProps.reagents.find(
                    (reagent: ImmutableReagent) => reagent.get('id') === newCircuit.get('reagentId')
                ),
                selectedOxime: nextProps.oximes.find(
                    (oxime: ImmutableOxime) => oxime.get('id') === newCircuit.get('oximeId')
                ),
                selectedMetal: nextProps.metals.find(
                    (metal: ImmutableMetal) => metal.get('id') === newCircuit.get('metalId')
                ),
                stages: newCircuit.get('stages').toJS(),
                streams: newCircuit.get('streams').toJS(),
                selectedCircuitUnit: newCircuit.get('circuitUnits') || UNIT_TYPES.US,
                selectedProductionUnit:
                    newCircuit.get('productionUnits') || PRODUCTION_UNIT_TYPES.TONS_DAILY,
                isothermStoichiometryFactor:
                    newCircuit.get('isothermStoichiometryFactor') ||
                    userDefaultStoiciometry ||
                    DEFAULT_ISOTHERM_STOICHIOMETRY_FACTOR,

                isModified: false,
                modal: null,
            };
        }
        return prevState;
    }

    constructor(props: Props) {
        super(props);

        const selectedCircuit = props.minChemCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === props.circuitId);
        if (!selectedCircuit) {
            this.state = MimicDiagramContainer.EmptyState;
            return;
        }
        this.state = {
            selectedCircuit,
            selectedReagent: props.reagents.find(
                (reagent: ImmutableReagent) =>
                    reagent.get('id') === selectedCircuit.get('reagentId')
            ),
            selectedOxime: props.oximes.find(
                (oxime: ImmutableOxime) => oxime.get('id') === selectedCircuit.get('oximeId')
            ),
            selectedMetal: props.metals.find(
                (metal: ImmutableMetal) => metal.get('id') === selectedCircuit.get('metalId')
            ),
            stages: selectedCircuit.get('stages').toJS(),
            streams: selectedCircuit.get('streams').toJS(),
            selectedCircuitUnit: selectedCircuit.get('circuitUnits'),
            selectedProductionUnit: selectedCircuit.get('productionUnits'),
            isothermStoichiometryFactor: selectedCircuit.get('isothermStoichiometryFactor'),

            isModified: false,
            modal: null,
        };
    }

    componentDidMount() {
        if (this.props.reagents.isEmpty() && this.props.oximes.isEmpty()) {
            this.props.fetchAllReagents();
        }
        if (this.props.metals.isEmpty()) {
            this.props.fetchAllMetals();
        }
        const circuit = this.getCircuit();
        if (!circuit) {
            this.props.fetchCircuit(this.props.circuitId);
        }

        QuickNavigatorObservable.setQuickNavigatorMinChem();
    }

    /**
     * When our props updates, check to see if they are different then update our state depending
     * @param {Props} prevProps
     */
    componentDidUpdate(prevProps: Props) {
        if (prevProps.metals.isEmpty() && !this.props.metals.isEmpty()) {
            // When we receive metals, select the first one.
            this.setState({ selectedMetal: this.props.metals.first() });
        }
        if (prevProps.reagents.isEmpty() && !this.props.reagents.isEmpty()) {
            // When we receive reagents, select the one the user selected.
            const selectedReagent = this.getCircuitReagent();
            this.setState({ selectedReagent });
        }
        if (prevProps.oximes.isEmpty() && !this.props.oximes.isEmpty()) {
            // When we receive oxime, select the one the user selected.
            const selectedOxime = this.getCircuitOxime();
            this.setState({ selectedOxime });
        }

        // Were we creating or updating a circuit? And the update/create stopped.
        // Aka was the create/update successful or unsuccessful?
        if (
            (prevProps.isCreatingCircuit && !this.props.isCreatingCircuit) ||
            (prevProps.isUpdatingCircuit && !this.props.isUpdatingCircuit)
        ) {
            this.handleHideModal(); // hide the modal.
        }
    }

    getCircuit = (): ImmutableCircuit =>
        this.props.minChemCircuitQuery
            .get('data')
            .find((circuit: ImmutableCircuit) => circuit.get('id') === this.props.circuitId);

    /**
     * Get the circuit reagent
     */
    getCircuitReagent = (): ImmutableReagent => {
        const circuit = this.getCircuit();
        if (!circuit || this.props.reagents.isEmpty()) {
            return null;
        }
        const reagentId = circuit.get('reagentId');
        return this.props.reagents.find(
            (reagent: ImmutableReagent) => reagent.get('id') === reagentId
        );
    };

    /**
     * Get the circuit oxime
     */
    getCircuitOxime = (): ImmutableOxime => {
        const circuit = this.getCircuit();
        if (!circuit || this.props.oximes.isEmpty()) {
            return null;
        }
        const oximeId = circuit.get('oximeId');
        return this.props.oximes.find((oxime: ImmutableOxime) => oxime.get('id') === oximeId);
    };

    /**
     * The circuit has a terminal loaded organic tank when the first tank
     * is connected from the first extractor.
     * Otherwise it isn't a terminal LO tank.
     */
    getTerminalLoadedOrganicTank = (): ?LooseStage => {
        return this.state.stages.find((stage: LooseStage) => {
            if (stage.stageType !== STAGE_TYPES.ORGANIC_TANK) {
                return false;
            }
            const streams = getStreamsForStage(stage, this.state.streams, this.state.stages);
            return Boolean(
                streams.find((stream: LooseStream) => {
                    const relatedStages = getStagesForStream(stream, this.state.stages);
                    return (
                        relatedStages.from &&
                        (relatedStages.from.stageType === STAGE_TYPES.EXTRACT ||
                            relatedStages.from.stageType === STAGE_TYPES.WASHER) &&
                        relatedStages.from.location === 1
                    );
                })
            );
        });
    };

    /**
     * The circuit has a terminal loaded organic tank when the first tank
     * is connected from the first extractor, and is connected to the first stripper.
     * Otherwise it isn't a terminal LO tank.
     */
    hasTerminalLoadedOrganicTankIsActive = () => {
        return Boolean(this.getTerminalLoadedOrganicTank());
    };

    /**
     * Gets the washer between extraction and stripping.
     */
    getETSWasher = (): ?LooseStage => {
        const etsWasher = this.state.stages.find(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.WASHER && stage.location === 1
        );
        if (!etsWasher) {
            return null;
        }
        return etsWasher;
    };

    hasETSWasher = (): boolean => Boolean(this.getETSWasher());

    /**
     * Gets the washer between stripping and extraction.
     */
    getSTEWasher = (): ?LooseStage => {
        const steWasher = this.state.stages.find(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.WASHER && stage.location === 2
        );
        if (!steWasher) {
            return null;
        }
        return steWasher;
    };

    hasSTEWasher = (): boolean => Boolean(this.getSTEWasher());

    /**
     * Get the number of extractors on the mimic diagram
     */
    getNumberOfExtractors = (): number =>
        this.state.stages.filter((stage: LooseStage) => stage.stageType === STAGE_TYPES.EXTRACT)
            .length;

    /**
     * Get the number of strippers on the mimic diagram
     */
    getNumberOfStrippers = (): number =>
        this.state.stages.filter((stage: LooseStage) => stage.stageType === STAGE_TYPES.STRIP)
            .length;

    getLoadedOrganicTanks = (): Array<LooseStage> =>
        this.state.stages.filter(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.ORGANIC_TANK
        );

    /**
     * Whether the circuit is savable or not.
     */
    isValidCircuit = (): boolean =>
        !this.props.loadingCircuit &&
        !this.props.fetchingSingleCircuit &&
        !this.props.loadingReagents &&
        !this.props.loadingOximes &&
        !this.props.loadingMetals &&
        Boolean(this.state.selectedMetal) &&
        Boolean(this.state.selectedCircuitUnit) &&
        Boolean(this.state.selectedProductionUnit) &&
        Boolean(this.state.selectedReagent || this.state.selectedOxime) &&
        this.state.stages.length !== 0 &&
        this.state.streams.length !== 0;

    /**
     * Gets the save data used by save as.
     */
    getSaveAsCircuitData = () => ({
        metalId: this.state.selectedMetal.get('id'),
        reagentId: this.state.selectedReagent ? this.state.selectedReagent.get('id') : null,
        oximeId: this.state.selectedOxime ? this.state.selectedOxime.get('id') : null,
        circuitUnits: this.state.selectedCircuitUnit,
        productionUnits: this.state.selectedProductionUnit,
        isothermStoichiometryFactor: this.state.isothermStoichiometryFactor,
        stages: this.state.stages.map((stage: LooseStage) => ({
            stageType: stage.stageType,
            location: stage.location,
            properties: {
                ...stage.properties,
                stageId: null,
            },
        })),
        streams: this.state.streams.map((stream: LooseStream) => ({
            from: stream.from || getStageById(stream.fromStageId, this.state.stages),
            to: stream.to || getStageById(stream.toStageId, this.state.stages),
            streamType: stream.streamType,
            streamCircuit: stream.streamCircuit,
        })),
    });

    /**
     * Gets the save data used by save as.
     */
    getSaveCircuitData = () => ({
        metalId: this.state.selectedMetal.get('id'),
        reagentId: this.state.selectedReagent ? this.state.selectedReagent.get('id') : null,
        oximeId: this.state.selectedOxime ? this.state.selectedOxime.get('id') : null,
        circuitUnits: this.state.selectedCircuitUnit,
        productionUnits: this.state.selectedProductionUnit,
        isothermStoichiometryFactor: this.state.isothermStoichiometryFactor,
        stages: this.state.stages.map((s) => ({
            ...s, // prevents modifications in legacy mappers
        })),
        streams: this.state.streams.map((s) => ({
            ...s, // prevents modifications in legacy mappers
        })),
    });

    getStreamData = (stream: LooseStream, stages: Array<LooseStage>) => {
        let fromStageType = null;
        let fromStageLocation = null;
        let fromStageId = null;

        let toStageId = null;
        let toStageType = null;
        let toStageLocation = null;

        if (stream.from) {
            fromStageType = stream.from.stageType;
            fromStageLocation = stream.from.location;
        } else {
            if (stream.fromStageId !== null) {
                fromStageId = stream.fromStageId;
                const fromStage = stages.find(
                    (stage: LooseStage) => stage.id && stage.id === stream.fromStageId
                );
                if (fromStage) {
                    fromStageType = fromStage.stageType;
                    fromStageLocation = fromStage.location;
                }
            }
        }

        if (stream.to) {
            toStageType = stream.to.stageType;
            toStageLocation = stream.to.location;
        } else {
            if (stream.toStageId !== null) {
                toStageId = stream.toStageId;
                const toStage = stages.find(
                    (stage: LooseStage) => stage.id && stage.id === stream.toStageId
                );
                if (toStage) {
                    toStageType = toStage.stageType;
                    toStageLocation = toStage.location;
                }
            }
        }
        return {
            fromStageId,
            fromStageType,
            fromStageLocation,
            toStageId,
            toStageType,
            toStageLocation,
        };
    };

    /**
     * Adds a stage to our circuit,
     * This creates the streams as well.
     */
    addStage = (
        stageType: StageTypeConstant,
        location: number,
        prevState: CircuitState
    ): CircuitState => {
        const steWasher = this.getSTEWasher();
        const etsWasher = this.getETSWasher();
        const oppositeStageType =
            stageType === STAGE_TYPES.EXTRACT ? STAGE_TYPES.STRIP : STAGE_TYPES.EXTRACT;
        const lastOppositeStage =
            steWasher ||
            prevState.stages
                .slice()
                .reverse()
                .find((stage: RawStage) => stage.stageType === oppositeStageType);
        const firstOppositeStage =
            etsWasher ||
            prevState.stages.find((stage: LooseStage) => stage.stageType === oppositeStageType);
        const lastSameStage = prevState.stages
            .slice()
            .reverse()
            .find((stage: LooseStage) => stage.stageType === stageType);

        let newStage = {
            stageType,
            location,
        };

        // Was this stage previously deleted then we want to re-add it?
        const circuit = this.getCircuit();
        if (circuit) {
            const serverStage = circuit
                .get('stages')
                .find(
                    (stage: ImmutableStage) =>
                        stage.get('stageType') === stageType && stage.get('location') === location
                );
            if (serverStage) {
                // Stage originally exists on server.
                newStage = serverStage.toJS();
            }
        }

        // Save the streams that should be kept.
        let streams = prevState.streams.filter((stream: LooseStream) => {
            const {
                fromStageType,
                fromStageLocation,
                toStageType,
                toStageLocation,
            } = this.getStreamData(stream, prevState.stages);

            let mustDeleteThisStream = false;
            if (lastSameStage) {
                mustDeleteThisStream =
                    // Aqueous BLEED from last extractor
                    (stageType === STAGE_TYPES.EXTRACT && // we are adding an extractor
                    stream.streamType === STREAM_TYPES.BLEED && // so delete the last bleed
                        stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                        fromStageLocation === lastSameStage.location &&
                        fromStageType === lastSameStage.stageType) || // Aqueous organic feed or continue.
                    (stageType === STAGE_TYPES.EXTRACT && // we are adding an extractor
                    (stream.streamType === STREAM_TYPES.CONTINUE ||
                        stream.streamType === STREAM_TYPES.FEED) && // so delete the organic stream into the last extractor
                        stream.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                        toStageLocation === lastSameStage.location &&
                        toStageType === lastSameStage.stageType) || // Electrolyte FEED
                    (stageType === STAGE_TYPES.STRIP && // we are adding a stripper
                    stream.streamType === STREAM_TYPES.FEED && // so delete the feed stream to the last stripper
                        stream.streamCircuit === STREAM_CIRCUITS.ELECTROLYTE &&
                        toStageLocation === lastSameStage.location &&
                        toStageType === lastSameStage.stageType) || // Electrolyte organic bleed or continue
                    (stageType === STAGE_TYPES.STRIP && // we are adding a stripper
                    (stream.streamType === STREAM_TYPES.CONTINUE ||
                        stream.streamType === STREAM_TYPES.BLEED) && // so delete the organic stream from the last stripper
                        stream.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                        fromStageLocation === lastSameStage.location &&
                        fromStageType === lastSameStage.stageType);
            }

            // Handle the case where we ONLY have strippers or we ONLY have extractors, and we are adding a stage of the opposite type.
            if (lastOppositeStage && firstOppositeStage && !lastSameStage) {
                mustDeleteThisStream =
                    mustDeleteThisStream ||
                    (stageType === STAGE_TYPES.EXTRACT && // we have strippers, no extractors, and we want to add an extractor
                    stream.streamType === STREAM_TYPES.BLEED && // is the organic bleed stream of last stripper?
                        stream.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                        fromStageLocation === lastOppositeStage.location &&
                        fromStageType === lastOppositeStage.stageType) ||
                    (stageType === STAGE_TYPES.EXTRACT && // we have strippers, no extractors, and we want to add an extractor
                    stream.streamType === STREAM_TYPES.FEED && // is it the organic feed stream of first stripper?
                        stream.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                        toStageLocation === firstOppositeStage.location &&
                        toStageType === firstOppositeStage.stageType) || // get the first stripper
                    (stageType === STAGE_TYPES.STRIP && // we have extractors, no strippers, and we want to add a stripper
                    stream.streamType === STREAM_TYPES.FEED && // is the organic feed stream of last extractor?
                        stream.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                        toStageLocation === lastOppositeStage.location &&
                        toStageType === lastOppositeStage.stageType) ||
                    (stageType === STAGE_TYPES.STRIP && // we have extractors, no strippers, and we want to add a stripper
                    stream.streamType === STREAM_TYPES.BLEED && // is the organic bleed stream of first extractor
                        stream.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                        fromStageLocation === firstOppositeStage.location &&
                        fromStageType === firstOppositeStage.stageType); // get the first stripper
            }

            // TODO: Delete all advanced streams on the circuit
            return !mustDeleteThisStream;
        });

        // Add the new stream feed and bleeds.
        if (stageType === STAGE_TYPES.EXTRACT) {
            // We are adding an extractor
            streams.push({
                // Add the bleed stream
                streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                streamType: STREAM_TYPES.BLEED,
                from: newStage,
                toStageId: null,
            });

            if (lastSameStage) {
                // link to previous extractor stage
                streams.push(
                    {
                        // Stream continue from previous stage.
                        streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                        streamType: STREAM_TYPES.CONTINUE,
                        to: newStage,
                        [`${lastSameStage.id ? 'fromStageId' : 'from'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                    },
                    {
                        // organic connection with previous stage.
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.CONTINUE,
                        from: newStage,
                        [`${lastSameStage.id ? 'toStageId' : 'to'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                    }
                );
            } else {
                // We will be the only extractor stage, therefore add the extractor feed
                streams.push({
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    streamType: STREAM_TYPES.FEED,
                    fromStageId: null,
                    to: newStage,
                });

                if (firstOppositeStage) {
                    // we will be the only extractor stage, therefore add the organic continue to the first stripper
                    streams.push({
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.CONTINUE,
                        from: newStage,
                        [`${
                            firstOppositeStage.id ? 'toStageId' : 'to'
                        }`]: firstOppositeStage.id || {
                            stageType: firstOppositeStage.stageType,
                            location: firstOppositeStage.location,
                        },
                    });
                } else {
                    // We will be the only stage: the only extractor stage.
                    streams.push({
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.BLEED,
                        from: newStage,
                        toStageId: null,
                    });
                }
            }

            if (lastOppositeStage) {
                // Do we have a last stripper stage?
                streams.push({
                    // connect the organic flow from the last stripper to our new (last) extractor
                    streamCircuit: STREAM_CIRCUITS.ORGANIC,
                    streamType: STREAM_TYPES.CONTINUE,
                    [`${lastOppositeStage.id ? 'fromStageId' : 'from'}`]: lastOppositeStage.id || {
                        stageType: lastOppositeStage.stageType,
                        location: lastOppositeStage.location,
                    },
                    to: newStage,
                });
            } else {
                // there are no strippers, therefore add the organic feed.
                streams.push({
                    // connect the organic flow feed into our new (last) extractor.
                    streamCircuit: STREAM_CIRCUITS.ORGANIC,
                    streamType: STREAM_TYPES.FEED,
                    fromStageId: null,
                    to: newStage,
                });
            }
        } else {
            // we are adding a stripper
            streams.push({
                // Add the feed stream
                streamCircuit: STREAM_CIRCUITS.ELECTROLYTE,
                streamType: STREAM_TYPES.FEED,
                fromStageId: null,
                to: newStage,
            });

            if (lastSameStage) {
                // link to previous stripper stage
                streams.push(
                    {
                        // Stream continue from previous stage.
                        streamCircuit: STREAM_CIRCUITS.ELECTROLYTE,
                        streamType: STREAM_TYPES.CONTINUE,
                        from: newStage,
                        [`${lastSameStage.id ? 'toStageId' : 'to'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                    },
                    {
                        // organic connection with previous stage.
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.CONTINUE,
                        to: newStage,
                        [`${lastSameStage.id ? 'fromStageId' : 'from'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                    }
                );
            } else {
                // We will be the only stripper stage, therefore add stripper bleed
                streams.push({
                    streamCircuit: STREAM_CIRCUITS.ELECTROLYTE,
                    streamType: STREAM_TYPES.BLEED,
                    from: newStage,
                    toStageId: null,
                });

                if (firstOppositeStage) {
                    // We will be the only stripper stage, therefore add the organic continue to the first extractor
                    streams.push({
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.CONTINUE,
                        [`${
                            firstOppositeStage.id ? 'fromStageId' : 'from'
                        }`]: firstOppositeStage.id || {
                            stageType: firstOppositeStage.stageType,
                            location: firstOppositeStage.location,
                        },
                        to: newStage,
                    });
                } else {
                    // We will be the only stage: the only stripper
                    streams.push({
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.FEED,
                        fromStageId: null,
                        to: newStage,
                    });
                }
            }

            if (lastOppositeStage) {
                // Do we have a last extractor stage?
                streams.push({
                    // connect the organic flow from our new (last) last stripper to the last extractor
                    streamCircuit: STREAM_CIRCUITS.ORGANIC,
                    streamType: STREAM_TYPES.CONTINUE,
                    from: newStage,
                    [`${lastOppositeStage.id ? 'toStageId' : 'to'}`]: lastOppositeStage.id || {
                        stageType: lastOppositeStage.stageType,
                        location: lastOppositeStage.location,
                    },
                });
            } else {
                // there are no extractors, therefore add the organic feed/bleed.
                streams.push({
                    // connect the organic flow bleed from our new (last) stripper
                    streamCircuit: STREAM_CIRCUITS.ORGANIC,
                    streamType: STREAM_TYPES.BLEED,
                    from: newStage,
                    toStageId: null,
                });
            }
        }

        // MODIFY BYPASSES/ORG BLENDS:
        // When we add or delete a stage, the bypasses and org blends are modified.
        // When we add an extractor, we must change the organic bypass into strip.
        streams = streams.map((stream: LooseStream) => {
            if (
                // Is it a bypass or a blend
                (stream.streamType !== STREAM_TYPES.BYPASS_FEED &&
                    stream.streamType !== STREAM_TYPES.BYPASS_BLEED &&
                    stream.streamType !== STREAM_TYPES.BYPASS_BLEND) ||
                // In the organic section
                stream.streamCircuit !== STREAM_CIRCUITS.ORGANIC
            ) {
                return stream; // do not modify if it is not a bypass or org blend.
            }

            const {
                fromStageType,
                fromStageLocation,
                toStageType,
                toStageLocation,
            } = this.getStreamData(stream, prevState.stages);
            if (!lastOppositeStage) {
                // If there are no stages in the opposite section, then nothing changes.
                return stream;
            }

            if (stageType === STAGE_TYPES.EXTRACT) {
                // We are adding an extractor stage.
                // Therefore we need to check for strip bypasses
                if (
                    // into the last extract stage
                    toStageLocation === lastSameStage.location &&
                    toStageType === lastSameStage.stageType
                ) {
                    return {
                        ...stream,
                        to: newStage,
                        toStageId: newStage.id,
                    };
                }
            } else {
                // We are adding a stripping stage.
                // Therefore we must check for extract bypasses.
                if (
                    // From the last strip stage
                    fromStageLocation === lastSameStage.location &&
                    fromStageType === lastSameStage.stageType
                ) {
                    return {
                        ...stream,
                        from: newStage,
                        fromStageId: newStage.id,
                    };
                }
            }
            // Nothing has changed.
            return stream;
        });

        return {
            stages: [...prevState.stages, newStage],
            streams: [...streams],
            isModified: true,
        };
    };

    /**
     * Delete a stage from our circuit,
     * This deletes the streams as well.
     *
     * @param {StageTypeConstant} stageType The type of stage which is being deleted
     * @param {number} location the location of the last stage.
     */
    deleteStage = (
        stageType: StageTypeConstant,
        location: number,
        prevStateParam: CircuitState
    ): CircuitState => {
        let prevState = prevStateParam;
        // If we have a terminal LO tank and we are deleting the first stage of a section,
        // we must also delete the terminal LO tank
        if (this.hasTerminalLoadedOrganicTankIsActive()) {
            if (location === 1) {
                prevState = this.handleDeleteTerminalLOTank(prevState);
            }
        }

        const stageToDelete = prevState.stages.find(
            (stage: LooseStage) => stage.stageType === stageType && stage.location === location
        );
        if (!stageToDelete) {
            throw new Error('Could not find stage that was marked for deletion.');
        }

        const steWasher = this.getSTEWasher();
        const etsWasher = this.getETSWasher();

        const oppositeStageType =
            stageType === STAGE_TYPES.EXTRACT ? STAGE_TYPES.STRIP : STAGE_TYPES.EXTRACT;

        // delete the washers when we delete stages, add them back at the end, if appropriate
        if (etsWasher) {
            prevState = this.deleteWasher(
                WASH_POSITIONS.ETS,
                stageToDelete,
                prevState.stages.find((stage: LooseStage) => stage.stageType === oppositeStageType),
                prevState
            );
        }
        if (steWasher) {
            const lastStripperStage = prevState.stages
                .slice()
                .reverse()
                .find((stage: LooseStage) => stage.stageType === oppositeStageType);
            prevState = this.deleteWasher(
                WASH_POSITIONS.STE,
                lastStripperStage,
                stageToDelete,
                prevState
            );
        }

        const mimicCircuit = new MimicCircuit(prevState);
        mimicCircuit.setMode(DIAGRAM_DISPLAY_MODES.SETUP);
        const mimicStage = mimicCircuit.getStageByDescription(
            stageToDelete.stageType,
            stageToDelete.location
        );
        if (mimicStage && mimicStage.isExtract()) {
            const loTankStream = mimicStage
                .getOutgoingOrganicStreams()
                .find((stream: IMimicStream) => stream.toStage && stream.toStage.isTank());
            // There can only ever be 1 outgoing organic stream
            if (loTankStream && loTankStream.toStage) {
                // $FlowIgnore
                const tank: IMimicLoadedOrganicTank = loTankStream.toStage;
                // If our extract stage that is being deleted is the first extractor connected
                // to an LO tank, then the LO tank must be deleted.
                if (tank.getFirstConnectedFromStage() === mimicStage) {
                    prevState = this.handleRemoveLoTank(tank, prevState);
                }
            }
        }

        const stages = prevState.stages.filter(
            (stage: LooseStage) => !(stage.stageType === stageType && stage.location >= location)
        );

        const lastOppositeStage = stages
            .slice()
            .reverse()
            .find((stage: LooseStage) => stage.stageType === oppositeStageType);
        const firstOppositeStage = stages.find(
            (stage: LooseStage) => stage.stageType === oppositeStageType
        );
        const lastSameStage = stages
            .slice()
            .reverse()
            .find(
                (stage: LooseStage) =>
                    stage.stageType === stageType && stage.location === location - 1
            );
        const firstSameStage = stages
            .slice()
            .find((stage: LooseStage) => stage.stageType === stageType);

        const incomingPlsSkipStream = getPlsSkipToStage(
            stageToDelete,
            prevState.stages,
            prevState.streams
        );

        const streams = prevState.streams
            .map((stream: LooseStream) => {
                // When we delete stages, we must also delete any streams that is associated to it
                // as well as any consequences.

                const {
                    fromStageType,
                    fromStageLocation,
                    toStageType,
                    toStageLocation,
                } = this.getStreamData(stream, prevState.stages);

                if (stageType === STAGE_TYPES.EXTRACT) {
                    if (incomingPlsSkipStream) {
                        // First check for skip streams.

                        if (incomingPlsSkipStream === stream) {
                            // if we are a skip stream into the stage that we want to delete
                            return null; // delete this stream.
                        }

                        const skipStageData = this.getStreamData(
                            incomingPlsSkipStream,
                            prevState.stages
                        );
                        const skipFromStageType = skipStageData.fromStageType;
                        const skipFromStageLocation = skipStageData.fromStageLocation;

                        if (
                            // Is the stream the feed stream related to the skip stream we deleted?
                            stream.streamType === STREAM_TYPES.FEED &&
                            toStageType === skipFromStageType &&
                            toStageLocation === skipFromStageLocation + 1
                        ) {
                            // We are the feed stream of the skip
                            // Replace the feed stream with a continue stream.
                            const feedRelatedStages = getStagesForStream(
                                incomingPlsSkipStream,
                                prevState.stages
                            );
                            const fromStage = feedRelatedStages.from;
                            const toStage = getNextStage(fromStage, prevState.stages);
                            return {
                                ...stream, // re-use stream ids
                                streamType: STREAM_TYPES.CONTINUE,
                                streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                                fromStageId: fromStage && fromStage.id,
                                from: fromStage,
                                toStageId: toStage && toStage.id,
                                to: toStage,
                            };
                        }
                    }
                }

                if (
                    (stream.streamType === STREAM_TYPES.BYPASS_FEED ||
                        stream.streamType === STREAM_TYPES.BYPASS_BLEED ||
                        stream.streamType === STREAM_TYPES.BYPASS_BLEND) &&
                    stream.streamCircuit === STREAM_CIRCUITS.ORGANIC
                ) {
                    // Are there any stages in the opposite section? If not, we must delete this bypass.
                    if (!lastSameStage) {
                        // Delete any bypass that is going to the section of the stage being deleted.
                        if (
                            toStageType === stageType ||
                            stream.streamType === STREAM_TYPES.BYPASS_BLEND
                        ) {
                            return null; // One of the streams should always be null
                        }
                        // Turn all bypasses into continue streams.
                        // Replace all bypasses going into a stage of the opposite section with a continue stream.
                        const fromStage = stages.find(
                            (stage: LooseStage) =>
                                stage.stageType === toStageType &&
                                stage.location ===
                                    toStageLocation - (stageType === STAGE_TYPES.EXTRACT ? 1 : -1)
                        );
                        const toStage = stages.find(
                            (stage: LooseStage) =>
                                stage.stageType === toStageType &&
                                stage.location === toStageLocation
                        );
                        return {
                            ...stream, // keep stream ids
                            streamType: STREAM_TYPES.CONTINUE,
                            streamCircuit: STREAM_CIRCUITS.ORGANIC,
                            fromStageId: fromStage && fromStage.id,
                            from: fromStage,
                            toStageId: toStage && toStage.id,
                            to: toStage,
                        };
                    }

                    // Are we deleting an extract stage?
                    if (stageType === STAGE_TYPES.EXTRACT) {
                        // Are we deleting a stage to which this bypass goes to?
                        if (toStageType === stageType && toStageLocation === location) {
                            // If so, we must change this stream's to.
                            const prevStage = stages.find(
                                (stage: LooseStage) =>
                                    stage.stageType === toStageType &&
                                    stage.location === toStageLocation - 1
                            );
                            return {
                                ...stream,
                                to: prevStage,
                                toStageId: prevStage && prevStage.id,
                            };
                        } else if (
                            toStageType === stageType &&
                            toStageLocation === location - 1 &&
                            fromStageType === STAGE_TYPES.STRIP
                        ) {
                            return null; // we want to delete the bypass feed
                        }
                    } else {
                        // We are deleting a strip stage.

                        // Are we deleting a stage to which this bypass goes to?
                        if (fromStageType === stageType && fromStageLocation === location) {
                            // If so, we must change this stream's to.
                            const prevStage = stages.find(
                                (stage: LooseStage) =>
                                    stage.stageType === fromStageType &&
                                    stage.location === fromStageLocation - 1
                            );
                            return {
                                ...stream,
                                from: prevStage,
                                fromStageId: prevStage && prevStage.id,
                            };
                        } else if (
                            fromStageType === stageType &&
                            fromStageLocation === location - 1 &&
                            toStageType === STAGE_TYPES.EXTRACT
                        ) {
                            return null; // we want to delete the bypass feed
                        }
                    }
                }

                if (
                    (fromStageLocation === location && fromStageType === stageType) || // do we come from the stage we are deleting?
                    (toStageLocation === location && toStageType === stageType) // or are we going to the stage we are deleting?
                ) {
                    return null; // we want to delete these streams.
                }

                return stream;
            })
            .filter(Boolean); // remove any nulls

        if (stageType === STAGE_TYPES.EXTRACT) {
            // we are deleting the last extractor
            if (lastSameStage) {
                // do we have a previous extractor?
                // Does the last extract have an pls bleed?
                if (!getPlsBleedFromStage(lastSameStage, stages, streams)) {
                    // if it doesn't have a bleed, then create one.
                    streams.push({
                        // add the aqueous bleed to the previous extractor
                        streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                        streamType: STREAM_TYPES.BLEED,
                        toStageId: null,
                        [`${lastSameStage.id ? 'fromStageId' : 'from'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                    });
                }

                if (lastOppositeStage) {
                    // is there a stripper stage?
                    streams.push({
                        // connect the organic circuit from the last stripper to the previous extractor
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.CONTINUE,
                        [`${
                            lastOppositeStage.id ? 'fromStageId' : 'from'
                        }`]: lastOppositeStage.id || {
                            stageType: lastOppositeStage.stageType,
                            location: lastOppositeStage.location,
                        },
                        [`${lastSameStage.id ? 'toStageId' : 'to'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                    });
                } else {
                    // there are no strippers, therefore add an organic feed.
                    streams.push({
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.FEED,
                        fromStageId: null,
                        [`${lastSameStage.id ? 'toStageId' : 'to'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                    });
                }
            } else {
                // We only had 1 extractor, and now we have 0.
                if (lastOppositeStage) {
                    streams.push({
                        // add the organic bleed to the last stripper
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.BLEED,
                        [`${
                            lastOppositeStage.id ? 'fromStageId' : 'from'
                        }`]: lastOppositeStage.id || {
                            stageType: lastOppositeStage.stageType,
                            location: lastOppositeStage.location,
                        },
                        toStageId: null,
                    });

                    // firstOppositeStage cannot be null if we have a last opposite stage...
                    streams.push({
                        // add the organic feed to the first stripper
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.FEED,
                        fromStageId: null,
                        [`${
                            firstOppositeStage.id ? 'toStageId' : 'to'
                        }`]: firstOppositeStage.id || {
                            stageType: firstOppositeStage.stageType,
                            location: firstOppositeStage.location,
                        },
                    });
                }
            }
        } else {
            // we are deleting a stripper
            if (lastSameStage) {
                // do we have a previous stripper?
                // Does the last stripper have an electrolyte feed?
                if (!getElectrolyteFeedToStage(lastSameStage, stages, streams)) {
                    // if it doesn't have a feed, then create one.
                    streams.push({
                        // add the electrolyte feed to the previous stripper
                        streamCircuit: STREAM_CIRCUITS.ELECTROLYTE,
                        streamType: STREAM_TYPES.FEED,
                        fromStageId: null,
                        [`${lastSameStage.id ? 'toStageId' : 'to'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                    });
                }

                if (lastOppositeStage) {
                    // is there an extractor stage?
                    streams.push({
                        // connect the organic circuit from the last extractor to the previous stripper
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.CONTINUE,
                        [`${lastSameStage.id ? 'fromStageId' : 'from'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                        [`${lastOppositeStage.id ? 'toStageId' : 'to'}`]: lastOppositeStage.id || {
                            stageType: lastOppositeStage.stageType,
                            location: lastOppositeStage.location,
                        },
                    });
                } else {
                    // there are no extractors, therefore add an organic bleed.
                    streams.push({
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.BLEED,
                        [`${lastSameStage.id ? 'fromStageId' : 'from'}`]: lastSameStage.id || {
                            stageType: lastSameStage.stageType,
                            location: lastSameStage.location,
                        },
                        toStageId: null,
                    });
                }
            } else {
                // We only had 1 stripper, and now we have 0.
                if (lastOppositeStage) {
                    streams.push({
                        // add the organic feed to the last extractor
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.FEED,
                        fromStageId: null,
                        [`${lastOppositeStage.id ? 'toStageId' : 'to'}`]: lastOppositeStage.id || {
                            stageType: lastOppositeStage.stageType,
                            location: lastOppositeStage.location,
                        },
                    });

                    // firstOppositeStage cannot be null if we have a last opposite stage...
                    streams.push({
                        // add the organic bleed to the first extractor
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        streamType: STREAM_TYPES.BLEED,
                        [`${
                            firstOppositeStage.id ? 'fromStageId' : 'from'
                        }`]: firstOppositeStage.id || {
                            stageType: firstOppositeStage.stageType,
                            location: firstOppositeStage.location,
                        },
                        toStageId: null,
                    });
                }
            }
        }

        let newState = {
            stages,
            streams,
        };
        if (location > 1 && lastSameStage && (lastOppositeStage || firstOppositeStage)) {
            const terminalLoadedOrganicTank = this.getTerminalLoadedOrganicTank();
            if (etsWasher) {
                const washerFrom =
                    stageType === STAGE_TYPES.EXTRACT ? firstSameStage : firstOppositeStage;
                const washerTo =
                    stageType === STAGE_TYPES.EXTRACT
                        ? terminalLoadedOrganicTank || firstOppositeStage
                        : terminalLoadedOrganicTank || firstSameStage;
                newState = this.addWasher(WASH_POSITIONS.ETS, washerFrom, washerTo, newState);
            }
            if (steWasher) {
                const washerFrom =
                    stageType === STAGE_TYPES.EXTRACT ? lastOppositeStage : lastSameStage;
                const washerTo =
                    stageType === STAGE_TYPES.EXTRACT ? lastSameStage : lastOppositeStage;
                newState = this.addWasher(WASH_POSITIONS.STE, washerFrom, washerTo, newState);
            }
        }

        return newState;
    };

    /**
     * Delete a stream from our circuit
     * Does not update the state but returns an array of streams without removed stream
     */
    deleteStream = (
        streamToRemove: LooseStream,
        streams: Array<LooseStream>,
        stages: Array<LooseStage>
    ): Array<LooseStream> => {
        // Remove the stream from state
        const filteredStreams = streams.filter((stream: LooseStream) => {
            // If both stream have id's, remove the stream by id
            if (stream.id && streamToRemove.id) {
                return stream.id !== streamToRemove.id;
            } else {
                if (
                    stream.streamType === streamToRemove.streamType &&
                    stream.streamCircuit === streamToRemove.streamCircuit
                ) {
                    const streamToRemoveRelatedStages = getStagesForStream(streamToRemove, stages);
                    const streamRelatedStages = getStagesForStream(stream, stages);
                    if (streamToRemoveRelatedStages.to && streamRelatedStages.to) {
                        return (
                            streamToRemoveRelatedStages.to.location !==
                                streamRelatedStages.to.location ||
                            streamToRemoveRelatedStages.to.stageType !==
                                streamRelatedStages.to.stageType
                        );
                    }
                    if (streamToRemoveRelatedStages.from && streamRelatedStages.from) {
                        return (
                            streamToRemoveRelatedStages.from.location !==
                                streamRelatedStages.from.location ||
                            streamToRemoveRelatedStages.from.stageType !==
                                streamRelatedStages.from.stageType
                        );
                    }
                }
                // other streams should remain untouched
                return true;
            }
        });
        return filteredStreams;
    };

    /**
     * Update the stage with our new stage properties.
     */
    handleChangeStageProperties = (
        stage: LooseStage,
        newStageProperties: MixerSettlerProperties
    ) => {
        this.setState((prevState: State) => {
            const stageIdx = prevState.stages.findIndex(
                (stateStage: LooseStage) =>
                    stateStage.location === stage.location &&
                    stateStage.stageType === stage.stageType
            );
            if (stageIdx === -1) {
                throw new Error('Could not find stage in state while changing stage properties?');
            }
            const stages = prevState.stages.map((stateStage: LooseStage) => {
                if (
                    stateStage.location !== stage.location ||
                    stateStage.stageType !== stage.stageType
                ) {
                    return stateStage; // Do not change the stage
                }
                return {
                    ...stateStage,
                    properties: newStageProperties,
                };
            });
            return {
                stages,
                isModified: true,
            };
        });
    };

    /**
     * When the user changes the isotherm stoichiometry factor in the sidebar
     */
    handleChangeIsothermStoichiometryFactor = (isothermStoichiometryFactor: number) =>
        this.setState({
            isothermStoichiometryFactor,
            isModified: true,
        });

    /**
     * When remove advanced stream value is clicked (Bleeds/Blends/New Feeds between stages)
     */
    handleRemoveAdvancedStreamValueClicked = (
        streamToRemove: LooseStream,
        advancedStreamType: AdvancedStreamTypeConstant
    ) => {
        this.setState((prevState: State) => {
            let filteredStreams = prevState.streams;
            let relatedStages = getStagesForStream(streamToRemove, prevState.stages);

            // 1. Check if there are two streams to remove: in case when we have bleed and new feed, only new feed
            // stream will have the remove icon however both streams should be removed and replaced by the continue stream
            if (
                streamToRemove.streamType === STREAM_TYPES.FEED ||
                advancedStreamType === ADVANCED_STREAMS_SETUP_TYPES.BLEED_BLEND
            ) {
                // Remove adjacent bleed stream
                filteredStreams = prevState.streams.filter((streamToKeep: LooseStream) => {
                    if (
                        streamToRemove.streamCircuit === streamToKeep.streamCircuit &&
                        streamToKeep.streamType === STREAM_TYPES.BLEED
                    ) {
                        // Get bleed stream that comes from previous stage
                        const fromStage = getStagesForStream(streamToKeep, prevState.stages).from;
                        if (fromStage && fromStage.stageType === relatedStages.to.stageType) {
                            return relatedStages.to.location - 1 !== fromStage.location;
                        }
                    }
                    return true;
                });
            } else if (
                streamToRemove.streamType === STREAM_TYPES.BLEED &&
                streamToRemove.streamCircuit === STREAM_CIRCUITS.ELECTROLYTE
            ) {
                // In the electrolyte circuit, removing a bleed stream actually means to remove the parallel configuration.
                // So we will remove the electrolyte feed stream as well.
                filteredStreams = prevState.streams.filter((streamToKeep: LooseStream) => {
                    if (
                        streamToRemove.streamCircuit === streamToKeep.streamCircuit &&
                        streamToKeep.streamType === STREAM_TYPES.FEED
                    ) {
                        const toStage = getStagesForStream(streamToKeep, prevState.stages).to; // S1
                        // Get the blend/feed stream that is not related to streamToRemove
                        if (toStage && toStage.stageType === relatedStages.from.stageType) {
                            return relatedStages.from.location - 1 !== toStage.location;
                        }
                        return false; // array-callback-return
                    } else {
                        return true;
                    }
                });
            } else if (
                streamToRemove.streamType === STREAM_TYPES.BYPASS_FEED ||
                streamToRemove.streamType === STREAM_TYPES.BYPASS_BLEED
            ) {
                // If the stream to remove is a bypass feed to a stage that has an LO tank,
                // ensure that there are other incoming streams to the LO tank, otherwise we must delete the tank.
                if (relatedStages.to && relatedStages.to.stageType === STAGE_TYPES.EXTRACT) {
                    // we must only check extract stages because LO tanks cannot be created with bypasses otherwise.
                    const nextPhysicalExtractor = getNextStage(relatedStages.to, prevState.stages);
                    const orgBypassBleed = getOrganicBypassBleed(
                        nextPhysicalExtractor,
                        prevState.stages,
                        prevState.streams
                    );
                    if (!orgBypassBleed) {
                        throw new Error(
                            '[Mimic Setup] Cannot remove a bypass feed if there are no sibling bypass bleed stream...'
                        );
                    }
                    const orgBypassBleedStages = getStagesForStream(
                        orgBypassBleed,
                        prevState.stages
                    );
                    if (
                        orgBypassBleedStages.to &&
                        orgBypassBleedStages.to.stageType === STAGE_TYPES.ORGANIC_TANK
                    ) {
                        const loTank = orgBypassBleedStages.to;
                        // Our bypass feed's sibling stream (the bypass bleed) is connected to an LO tank.
                        // Find all the streams going into the LO tank so that we can check if it has
                        // more than 1 incoming stream. If it doesn't, the tank must be removed and we can exit.
                        const streamsForLoTank = getStreamsForStage(
                            loTank,
                            prevState.streams,
                            prevState.stages
                        );
                        const hasIncomingOtherThanBypassBleed = Boolean(
                            streamsForLoTank.find((streamForLoTank: LooseStream) => {
                                if (streamForLoTank === orgBypassBleed) {
                                    // do not count the org bypass bleed because we want to see if there are other streams than that one.
                                    return false;
                                }
                                if (
                                    streamForLoTank.toStageId &&
                                    loTank.id &&
                                    streamForLoTank.toStageId === loTank.id
                                ) {
                                    // the stream goes to the LO tank and isn't the bypass bleed.
                                    return true;
                                }
                                const stagesForLoTankStream = getStagesForStream(
                                    streamForLoTank,
                                    prevState.stages
                                );
                                if (stagesForLoTankStream.to === loTank) {
                                    // the stream goes to the LO tank.
                                    return true;
                                }
                                return false;
                            })
                        );
                        if (!hasIncomingOtherThanBypassBleed) {
                            return {
                                ...this.handleRemoveLoTank(loTank, prevState),
                                isModified: true,
                            };
                        }
                    }
                }

                // Remove adjacent bypass stream since both will be 'replaced' back with continue stream
                filteredStreams = prevState.streams.filter((streamToKeep: LooseStream) => {
                    if (
                        streamToKeep.streamCircuit === STREAM_CIRCUITS.ORGANIC &&
                        (streamToKeep.streamType === STREAM_TYPES.BYPASS_FEED ||
                            streamToKeep.streamType === STREAM_TYPES.BYPASS_BLEED)
                    ) {
                        const potentialBleedStreamFromStage = getStagesForStream(
                            streamToKeep,
                            prevState.stages
                        ).from; // S1

                        if (
                            potentialBleedStreamFromStage.stageType !== relatedStages.to.stageType
                        ) {
                            return true; // we want to keep any bypasses from the opposite section.
                        }

                        if (potentialBleedStreamFromStage.stageType === STAGE_TYPES.EXTRACT) {
                            return (
                                potentialBleedStreamFromStage.location - 1 !==
                                relatedStages.to.location
                            );
                        } else {
                            return (
                                potentialBleedStreamFromStage.location + 1 !==
                                relatedStages.to.location
                            );
                        }
                    } else {
                        return true;
                    }
                });
            } else if (streamToRemove.streamType === STREAM_TYPES.SKIP) {
                // When removing skip stream, we also need to remove feed and bleed associated with it
                filteredStreams = prevState.streams.filter((streamToKeep: LooseStream) => {
                    if (
                        streamToKeep.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                        streamToKeep.streamType === STREAM_TYPES.FEED
                    ) {
                        const stage = getStagesForStream(streamToKeep, prevState.stages).to;
                        if (!stage) {
                            throw new Error('PLS Feed stream does not have a TO stage.');
                        }
                        const shouldKeep = !(
                            stage.stageType === relatedStages.from.stageType &&
                            stage.location === relatedStages.from.location + 1
                        );
                        return shouldKeep;
                    } else if (
                        streamToKeep.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                        streamToKeep.streamType === STREAM_TYPES.BLEND
                    ) {
                        const stage = getStagesForStream(streamToKeep, prevState.stages).to;
                        if (!stage) {
                            throw new Error('PLS Blend stream does not have a TO stage.');
                        }
                        const shouldKeep = !(
                            stage.stageType === relatedStages.to.stageType &&
                            stage.location === relatedStages.to.location
                        ); // this is the blend stream connected to a skip stream
                        return shouldKeep;
                    } else if (
                        streamToKeep.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                        streamToKeep.streamType === STREAM_TYPES.BLEED
                    ) {
                        const stage = getStagesForStream(streamToKeep, prevState.stages).from;
                        if (!stage) {
                            throw new Error('PLS Bleed stream does not have a from stage.');
                        }
                        const shouldKeep = !(
                            stage.stageType === relatedStages.to.stageType &&
                            (stage.location === relatedStages.to.location - 1 || // the bleed out of the inner cascade
                                stage.location === relatedStages.from.location)
                        ); // the bleed connected to the skip stream
                        return shouldKeep;
                    } else {
                        return true;
                    }
                });
            }

            // 2. Remove the stream itself from prevState array of streams
            filteredStreams = this.deleteStream(streamToRemove, filteredStreams, prevState.stages);

            // 3. Check if there is a continue stream, if there is non, add Continue stream
            // get related stages - between which stages we are adding the Continue Stream
            if (
                streamToRemove.streamType === STREAM_TYPES.BYPASS_FEED ||
                streamToRemove.streamType === STREAM_TYPES.BYPASS_BLEED
            ) {
                const sisterStageLocation =
                    relatedStages.to.stageType === STAGE_TYPES.EXTRACT ? 1 : -1;
                relatedStages = {
                    from: prevState.stages.find(
                        (stage: LooseStage) =>
                            stage.location === relatedStages.to.location + sisterStageLocation &&
                            stage.stageType === relatedStages.to.stageType
                    ),
                    to: relatedStages.to,
                };
            } else {
                if (!relatedStages.to) {
                    // if there is no to stage, it is the next stage after from in aqueous circuit and vice versa in electrolyte
                    relatedStages.to = prevState.stages.find((stage: LooseStage) => {
                        if (
                            streamToRemove.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                            stage.stageType === STAGE_TYPES.EXTRACT
                        ) {
                            // in aqueous stream, from stage is one location before the to stage
                            return relatedStages.from.location + 1 === stage.location;
                        } else if (
                            streamToRemove.streamCircuit === STREAM_CIRCUITS.ELECTROLYTE &&
                            stage.stageType === STAGE_TYPES.STRIP
                        ) {
                            // in case of electrolyte stream, we are going backwards
                            return relatedStages.from.location - 1 === stage.location;
                        } else {
                            return false;
                        }
                    });
                }
                if (!relatedStages.from) {
                    // if there is no from stage, it is the previous stage before to in aqueous circuit and vice versa in electrolyte
                    relatedStages.from = prevState.stages.find((stage: LooseStage) => {
                        if (
                            streamToRemove.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                            stage.stageType === STAGE_TYPES.EXTRACT
                        ) {
                            // in aqueous stream, from stage is one location before the to stage
                            return (
                                stage.stageType === STAGE_TYPES.EXTRACT &&
                                relatedStages.to.location - 1 === stage.location
                            );
                        } else if (
                            streamToRemove.streamCircuit === STREAM_CIRCUITS.ELECTROLYTE &&
                            stage.stageType === STAGE_TYPES.STRIP
                        ) {
                            // in case of electrolyte stream, we are going backwards
                            return relatedStages.to.location + 1 === stage.location;
                        } else {
                            return false;
                        }
                    });
                }
            }

            // Add continue streams between skip from stage and next stage and between skip to stage and previous stage
            if (streamToRemove.streamType === STREAM_TYPES.SKIP) {
                // Add continue streams between skip from stage and next stage
                const nextStage = prevState.stages.find(
                    (stage: LooseStage) =>
                        stage.stageType === relatedStages.from.stageType &&
                        stage.location === relatedStages.from.location + 1
                );
                // Look if there is a skip stream incoming in the nextStage
                const nextStageHasIncomingSkipStream = filteredStreams.find((stream) => {
                    if (
                        stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                        stream.streamType === STREAM_TYPES.SKIP
                    ) {
                        const stage = getStagesForStream(stream, prevState.stages).to;
                        return (
                            stage &&
                            stage.location === nextStage.location &&
                            stage.stageType === nextStage.stageType
                        );
                    }
                });

                // If we found the skip stream, we don't want to create a continue stream with the next stage since
                // this next stage already have an incoming skip stream of it's PLS circuit. Instead, we create a bleed
                // stream on the from stage of the deleted skip stream
                if (nextStageHasIncomingSkipStream) {
                    const bleedStreamFromStage = {
                        streamType: STREAM_TYPES.BLEED,
                        streamCircuit: streamToRemove.streamCircuit,
                        from: relatedStages.from,
                        fromStageId: relatedStages.from.id,
                        to: null,
                        toStageId: null,
                    };
                    filteredStreams.push(bleedStreamFromStage);
                } else if (nextStage) {
                    const continueStreamFromStage = {
                        streamType: STREAM_TYPES.CONTINUE,
                        streamCircuit: streamToRemove.streamCircuit,
                        from: relatedStages.from,
                        fromStageId: relatedStages.from.id,
                        to: nextStage,
                        toStageId: nextStage.id,
                    };
                    filteredStreams.push(continueStreamFromStage);
                }

                const prevStage = prevState.stages.find(
                    (stage: LooseStage) =>
                        stage.stageType === relatedStages.to.stageType &&
                        stage.location === relatedStages.to.location - 1
                );
                // Look if there is a skip stream outgoing from the prevStage
                const previousStageHasOutgoingSkip = filteredStreams.find((stream) => {
                    if (
                        stream.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                        stream.streamType === STREAM_TYPES.SKIP
                    ) {
                        const stage = getStagesForStream(stream, prevState.stages).from;
                        return (
                            stage &&
                            stage.location === prevStage.location &&
                            stage.stageType === prevStage.stageType
                        );
                    }
                });
                // If we found the skip stream, we don't want to create a continue stream with the previous stage since
                // this previous stage already have an outgoing skip stream of it's PLS circuit. Instead, we create a feed
                // stream on the to stage of the deleted skip stream
                if (previousStageHasOutgoingSkip) {
                    const feedStream = {
                        streamType: STREAM_TYPES.FEED,
                        streamCircuit: streamToRemove.streamCircuit,
                        to: relatedStages.to,
                        toStageId: relatedStages.to.id,
                        from: null,
                        fromStageId: null,
                    };
                    filteredStreams.push(feedStream);
                } else if (prevStage) {
                    const continueStreamToStage = {
                        streamType: STREAM_TYPES.CONTINUE,
                        streamCircuit: streamToRemove.streamCircuit,
                        from: prevStage,
                        fromStageId: prevStage.id,
                        to: relatedStages.to,
                        toStageId: relatedStages.to.id,
                    };
                    filteredStreams.push(continueStreamToStage);
                }
            } else if (streamToRemove.streamType !== STREAM_TYPES.BYPASS_BLEND) {
                // organic blend do not need continue stream.
                // Check if there is a continue stream
                const existingContinueStream = prevState.streams.find((stream: LooseStream) => {
                    if (
                        (stream.streamType === STREAM_TYPES.CONTINUE ||
                            stream.streamType === STREAM_TYPES.SKIP) &&
                        stream.streamCircuit === streamToRemove.streamCircuit
                    ) {
                        // Check only for blend, since in case of feed stream, there would be no continue
                        if (
                            streamToRemove.streamType === STREAM_TYPES.BLEND ||
                            advancedStreamType === ADVANCED_STREAMS_SETUP_TYPES.BLEND
                        ) {
                            // compare to stages
                            if (streamToRemove.toStageId && stream.toStageId) {
                                return streamToRemove.toStageId === stream.toStageId;
                            } else if (streamToRemove.to && stream.to) {
                                return (
                                    streamToRemove.to.location === stream.to.location &&
                                    streamToRemove.to.stageType === stream.to.stageType
                                );
                            }
                        } else if (streamToRemove.streamType === STREAM_TYPES.BLEED) {
                            // compare from stages
                            if (streamToRemove.fromStageId && stream.fromStageId) {
                                return streamToRemove.fromStageId === stream.fromStageId;
                            } else if (streamToRemove.from && stream.from) {
                                return (
                                    streamToRemove.from.location === stream.from.location &&
                                    streamToRemove.from.stageType === stream.from.stageType
                                );
                            }
                        }
                        return false;
                    }
                    return false;
                });

                if (!existingContinueStream) {
                    // if there is no continue stream between these related stages, add one
                    const continueStream = {
                        streamType: STREAM_TYPES.CONTINUE,
                        streamCircuit: streamToRemove.streamCircuit,
                        from: relatedStages.from,
                        fromStageId: relatedStages.from && relatedStages.from.id,
                        to: relatedStages.to,
                        toStageId: relatedStages.to && relatedStages.to.id,
                    };
                    filteredStreams.push(continueStream);
                }
            }

            return {
                streams: [...filteredStreams],
                isModified: true,
            };
        });
    };

    /**
     * When the stage count changes, create or delete the amount of stages.
     */
    handleStageCountChange = (stageType: StageTypeConstant, value: number) => {
        this.setState((prevState: State) => {
            let numberOfStages = null;
            if (stageType === STAGE_TYPES.EXTRACT) {
                numberOfStages = this.getNumberOfExtractors();
            } else {
                numberOfStages = this.getNumberOfStrippers();
            }
            let curState = prevState;
            if (numberOfStages < value) {
                for (
                    let location = numberOfStages + 1;
                    location <= value && location <= MAX_STAGE_COUNT;
                    location++
                ) {
                    curState = this.addStage(stageType, location, curState);
                }
            } else if (numberOfStages > value) {
                for (
                    let location = numberOfStages;
                    location > value && location > MIN_STAGE_COUNT;
                    location--
                ) {
                    curState = this.deleteStage(stageType, location, curState);
                }
            }
            return {
                ...curState,
                isModified: true,
            };
        });
    };

    addWasher = (
        location: WashPosition,
        fromStage: LooseStage,
        toStage: LooseStage,
        prevState: CircuitState
    ): CircuitState => {
        // The initial stream is the stream that initially links the from and to stages together.
        // This is the stream that will be split in to and half will be redirected to the washer,
        // the other half will be redirected from the washer.
        const initialStream = prevState.streams.find((stream: LooseStream) => {
            const streamStages = getStagesForStream(stream, prevState.stages);
            if (!streamStages.from || !streamStages.to) {
                // streams without a from and to cannot be the initial stream.
                return false;
            }
            return (
                streamStages.from.location === fromStage.location &&
                streamStages.to.location === toStage.location &&
                streamStages.from.stageType === fromStage.stageType &&
                streamStages.to.stageType === toStage.stageType
            );
        });
        if (!initialStream) {
            throw new Error('Cannot add washer, could not find the initial stream to split.');
        }
        const terminalLoadedOrganicTank = this.getTerminalLoadedOrganicTank();

        const newWasher = {
            stageType: STAGE_TYPES.WASHER,
            location: WASH_POSITIONS_TO_LOCATION[location],
        };

        // Reroute any existing bypasses
        const reroutedStreams = prevState.streams
            .map((stream: LooseStream) => {
                const relatedStages = getStagesForStream(stream, prevState.stages);
                if (!relatedStages.from || !relatedStages.to) {
                    // any streams without a from/to shouldn't be rerouted for washers.
                    return stream;
                }
                if (stream === initialStream) {
                    return null;
                }
                const streamToStage = relatedStages.to;
                const streamFromStage = relatedStages.from;
                if (
                    location === WASH_POSITIONS.STE &&
                    streamToStage.stageType === STAGE_TYPES.EXTRACT
                ) {
                    if (streamFromStage.stageType === STAGE_TYPES.STRIP) {
                        if (stream.streamType === STREAM_TYPES.BYPASS_BLEED) {
                            // this is a barren organic bypass exiting stripping.
                            const updatedStream = {
                                ...stream,
                                to: newWasher,
                            };
                            delete updatedStream.toStageId; // the new washer has no id, delete the remnants from the spread operator.
                            return updatedStream;
                        } else {
                            // this is a barren organic bypass from stripping to extraction
                            const updatedStream = {
                                ...stream,
                                from: newWasher,
                            };
                            delete updatedStream.fromStageId; // the new washer has no id, delete the remnants from the spread operator.
                            return updatedStream;
                        }
                    }
                } else if (
                    location === WASH_POSITIONS.ETS &&
                    streamFromStage.location === 1 &&
                    streamFromStage.stageType === STAGE_TYPES.EXTRACT &&
                    streamToStage.stageType === STAGE_TYPES.STRIP
                ) {
                    // Reroute any bypass feed stream in stripping that comes from the first extractor
                    // to instead come from the ETS washer
                    const updatedStream = {
                        ...stream,
                        from: newWasher,
                    };
                    delete updatedStream.fromStageId; // the new washer has no id, delete the remnants from the spread operator.
                    return updatedStream;
                } else if (
                    location === WASH_POSITIONS.ETS &&
                    streamFromStage.location !== 1 &&
                    streamFromStage.stageType === STAGE_TYPES.EXTRACT &&
                    streamToStage.stageType === STAGE_TYPES.STRIP
                ) {
                    // Reroute any bypass bleed stream in extraction
                    // to instead go to the ETS washer
                    const updatedStream = {
                        ...stream,
                        to: newWasher,
                    };
                    delete updatedStream.toStageId; // the new washer has no id, delete the remnants from the spread operator.
                    return updatedStream;
                } else if (
                    location === WASH_POSITIONS.ETS &&
                    streamToStage === terminalLoadedOrganicTank
                ) {
                    // all streams to the terminal LO tank must be rerouted to the washer first
                    const updatedStream = {
                        ...stream,
                        to: newWasher,
                    };
                    delete updatedStream.toStageId; // the new washer has no id, delete the remnants from the spread operator.
                    return updatedStream;
                }

                return stream;
            })
            .filter(Boolean);

        // Split the existing stream (from the firstExtractor to the LO Tank/firstStripper) or (from the lastStripper to the lastExtractor)
        // Reroute the stream from the first extractor/last stripper
        // to the new washer
        const streamToWasher = {
            ...initialStream,
            to: newWasher,
        };
        delete streamToWasher.toStageId; // the new washer has no id, delete the remnants from the spread operator.
        // Add a new stream from the washer to the (LO Tank/firstStripper) or to the (lastExtractor)
        const streamFromWasher = {
            streamType: STREAM_TYPES.CONTINUE,
            streamCircuit: STREAM_CIRCUITS.ORGANIC,
            from: newWasher,
            to: toStage,
        };
        if (toStage.id) {
            streamFromWasher.toStageId = toStage.id; // the to stage may have already been saved, if it has (it has an id) add the stage id.
        }

        return {
            stages: [...prevState.stages, newWasher],
            streams: [...reroutedStreams, streamToWasher, streamFromWasher],
        };
    };

    deleteWasher = (
        location: WashPosition,
        fromStage: LooseStage,
        toStage: LooseStage,
        prevState: CircuitState
    ): CircuitState => {
        const washer = prevState.stages.find(
            (stage: LooseStage) =>
                stage.stageType === STAGE_TYPES.WASHER &&
                stage.location === WASH_POSITIONS_TO_LOCATION[location]
        );
        if (!washer) {
            return prevState;
        }

        const terminalLoadedOrganicTank = this.getTerminalLoadedOrganicTank();
        const firstStripper = prevState.stages.find(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.STRIP && stage.location === 1
        );
        const firstExtractor = prevState.stages.find(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.EXTRACT && stage.location === 1
        );
        const lastStripper = prevState.stages.find(
            (stage: LooseStage) =>
                stage.stageType === STAGE_TYPES.STRIP &&
                stage.location === this.getNumberOfStrippers()
        );
        const lastExtractor = prevState.stages.find(
            (stage: LooseStage) =>
                stage.stageType === STAGE_TYPES.EXTRACT &&
                stage.location === this.getNumberOfExtractors()
        );
        if (!lastStripper || !lastExtractor || !firstStripper || !firstExtractor) {
            throw new Error('Cannot delete a washer without extractors or strippers.');
        }

        let rerouteFromStage;
        let rerouteToStage;
        if (location === WASH_POSITIONS.ETS) {
            rerouteFromStage = firstExtractor;
            rerouteToStage = terminalLoadedOrganicTank || firstStripper;
        } else {
            rerouteFromStage = lastStripper;
            rerouteToStage = lastExtractor;
        }

        const washerStreams = getStreamsForStage(washer, prevState.streams, prevState.stages);

        const reroutedWasherStreams = washerStreams
            .map((stream: LooseStream) => {
                const relatedStages = getStagesForStream(stream, prevState.stages);
                const updatedStream = {
                    ...stream,
                };
                // if (relatedStages.from === washer && relatedStages.to === terminalLoadedOrganicTank) {
                //     return null; // we must delete this stream because it will be rerouted by another
                // }
                if (
                    stream.streamType === STREAM_TYPES.CONTINUE &&
                    relatedStages.to.location === washer.location &&
                    relatedStages.to.stageType === washer.stageType
                ) {
                    return null; // delete this stream because it will be duplicated by the from stream.
                }
                if (
                    relatedStages.to.location === washer.location &&
                    relatedStages.to.stageType === washer.stageType
                ) {
                    updatedStream.to = rerouteToStage;
                    if (rerouteToStage.id) {
                        updatedStream.toStageId = rerouteToStage.id;
                    } else {
                        delete updatedStream.toStageId;
                    }
                }
                if (
                    relatedStages.from.location === washer.location &&
                    relatedStages.from.stageType === washer.stageType
                ) {
                    updatedStream.from = rerouteFromStage;
                    if (rerouteFromStage.id) {
                        updatedStream.fromStageId = rerouteFromStage.id;
                    } else {
                        delete updatedStream.fromStageId;
                    }
                }
                return updatedStream;
            })
            .filter(Boolean);

        const streamsWithoutWasherStreams = prevState.streams.filter(
            (stream: LooseStream) => !washerStreams.includes(stream)
        );
        const stagesWithoutWasher = prevState.stages.filter(
            (stage: LooseStage) => stage !== washer
        );
        return {
            stages: stagesWithoutWasher,
            streams: [...streamsWithoutWasherStreams, ...reroutedWasherStreams],
        };
    };

    handleAddWasher = (location: WashPosition, fromStage: LooseStage, toStage: LooseStage) => {
        this.setState((prevState: State) => {
            return {
                ...this.addWasher(location, fromStage, toStage, prevState),
                isModified: true,
            };
        });
    };

    handleDeleteWasher = (location: WashPosition, fromStage: LooseStage, toStage: LooseStage) => {
        this.setState((prevState: State) => {
            return {
                ...this.deleteWasher(location, fromStage, toStage, prevState),
                isModified: true,
            };
        });
    };

    handleWashActiveChange = (location: WashPosition, active: boolean) => {
        let fromStage;
        let toStage;
        if (location === WASH_POSITIONS.ETS) {
            const firstExtractor = this.state.stages.find(
                (stage: LooseStage) =>
                    stage.stageType === STAGE_TYPES.EXTRACT && stage.location === 1
            );
            if (!firstExtractor) {
                throw new Error('Cannot add Extract to Strip Washer without extractor stages.');
            }
            const firstStripper = this.state.stages.find(
                (stage: LooseStage) => stage.stageType === STAGE_TYPES.STRIP && stage.location === 1
            );
            if (!firstStripper) {
                throw new Error('Cannot add Extract to Strip Washer without stripping stages.');
            }
            const terminalLoadedOrganicTank = this.getTerminalLoadedOrganicTank();

            // The from stage is always the first extract
            fromStage = firstExtractor;
            // The to stage is either the LO tank or the first stripper. The LO tank takes priority.
            toStage = terminalLoadedOrganicTank || firstStripper;
            if (active) {
                this.handleAddWasher(location, fromStage, toStage);
            } else {
                this.handleDeleteWasher(location, fromStage, toStage);
            }
        } else {
            const lastExtractor = this.state.stages.find(
                (stage: LooseStage) =>
                    stage.stageType === STAGE_TYPES.EXTRACT &&
                    stage.location === this.getNumberOfExtractors()
            );
            if (!lastExtractor) {
                throw new Error('Cannot add Strip to Extract Washer without extractor stages.');
            }
            const lastStripper = this.state.stages.find(
                (stage: LooseStage) =>
                    stage.stageType === STAGE_TYPES.STRIP &&
                    stage.location === this.getNumberOfStrippers()
            );
            if (!lastStripper) {
                throw new Error('Cannot add Strip to Extract Washer without stripping stages.');
            }

            fromStage = lastStripper;
            toStage = lastExtractor;
            if (active) {
                this.handleAddWasher(location, fromStage, toStage);
            } else {
                this.handleDeleteWasher(location, fromStage, toStage);
            }
        }
    };

    /**
     * When the tanks are changed from the sidebar.
     */
    handleTankChange = (tankType: StageTypeConstant, active: boolean) => {
        if (tankType !== STAGE_TYPES.ORGANIC_TANK) {
            throw new Error('Cannot remove tanks other than loaded organic tanks');
        }
        if (active) {
            this.handleAddTerminalLOTank();
        } else {
            this.handleRemoveAllLOTanks();
        }
    };

    /**
     * Removes all LO tanks starting with the furthest to the right,
     * and moving its way down the circuit towards the left.
     *
     * This removes all streams associated to the LO Tanks.
     */
    handleRemoveAllLOTanks = () => {
        const tanks = this.state.stages.filter(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.ORGANIC_TANK
        );
        tanks.reverse();

        this.setState((prevState: State) => {
            let state: CircuitState = {
                stages: prevState.stages,
                streams: prevState.streams,
            };
            tanks.forEach((loTank: LooseStage) => {
                if (loTank.location === 1) {
                    state = this.handleDeleteTerminalLOTank(state);
                } else {
                    state = this.handleRemoveLoTank(loTank, state);
                }
            });
            return {
                ...state,
                isModified: true,
            };
        });
    };

    /**
     * Adds an LO tank to the circuit.
     * Pre-requisites: extractors and strippers must be present.
     * Reroutes any necessary streams to/from the LO tank.
     *  Namely, any bypasses and the organic continue stream from the first extract stage/ETS washer
     */
    handleAddTerminalLOTank = () => {
        // Add terminal LO tank
        const firstExtractor = this.state.stages.find(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.EXTRACT && stage.location === 1
        );
        if (!firstExtractor) {
            throw new Error('Cannot add Loaded Organic tank without extractor stages.');
        }
        const firstStripper = this.state.stages.find(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.STRIP && stage.location === 1
        );
        if (!firstStripper) {
            throw new Error('Cannot add Loaded Organic tank without stripping stages.');
        }

        const existingTanks = this.getLoadedOrganicTanks();

        const newTank = {
            stageType: STAGE_TYPES.ORGANIC_TANK,
            location: existingTanks.length + 1,
        };

        // Create the stream that connects the LO tank and the strip stage
        const tankToStage = firstStripper;
        const newStreamFromTerminalLoadedOrganicTankToStrip = {
            streamType: STREAM_TYPES.CONTINUE,
            streamCircuit: STREAM_CIRCUITS.ORGANIC,
            from: newTank,
            to: tankToStage,
        };
        // $FlowIgnore
        if (tankToStage.id) {
            // $FlowIgnore
            newStreamFromTerminalLoadedOrganicTankToStrip.toStageId = tankToStage.id;
        }

        // Reroute the streams that go to strip.
        // Any bypass that exit extract (therefore enter strip 1) must be rerouted to the LO tank
        // Any bypass that enter strip (therefore exit extract 1) must be rerouted from the LO tank
        const reroutedStreams = this.state.streams.map((stream: LooseStream) => {
            const relatedStages = getStagesForStream(stream, this.state.stages);
            if (!relatedStages.to || !relatedStages.from) {
                return stream; // this stream should not be rerouted, keep the original copy
            }
            const toStage = relatedStages.to;
            const fromStage = relatedStages.from;
            if (
                toStage.stageType === STAGE_TYPES.STRIP &&
                (fromStage.stageType === STAGE_TYPES.EXTRACT ||
                    fromStage.stageType === STAGE_TYPES.WASHER)
            ) {
                // the stream is going to strip.
                if (toStage.location === 1) {
                    // all of the streams to the first stripper gets rerouted.
                    const updatedStream = {
                        ...stream,
                        to: newTank,
                    };
                    delete updatedStream.toStageId;
                    return updatedStream;
                }
                if (stream.streamType === STREAM_TYPES.BYPASS_FEED) {
                    // All of the bypass feeds to strip stages > 1 from the first
                    // extractor gets rerouted from the LO tank
                    if (
                        (fromStage.stageType === STAGE_TYPES.EXTRACT ||
                            fromStage.stageType === STAGE_TYPES.WASHER) &&
                        fromStage.location === 1
                    ) {
                        const updatedStream = {
                            ...stream,
                            from: newTank,
                        };
                        delete updatedStream.fromStageId;
                        return updatedStream;
                    }
                }
            }
            // No other changes to be made.
            return stream;
        });
        this.setState((prevState: State) => {
            return {
                stages: [...prevState.stages, newTank],
                streams: [...reroutedStreams, newStreamFromTerminalLoadedOrganicTankToStrip],
                isModified: true,
            };
        });
    };

    /**
     * Deletes the terminal loaded organic tank between extract and strip
     * Replaces the incoming stream and outgoing stream of the tank with a single stream
     * connecting the first extractor and the first stripper
     */
    handleDeleteTerminalLOTank = (prevState: CircuitState): CircuitState => {
        const terminalLoadedOrganicTank = this.getTerminalLoadedOrganicTank();
        if (!terminalLoadedOrganicTank) {
            return prevState;
        }
        const firstExtractor = prevState.stages.find(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.EXTRACT && stage.location === 1
        );
        if (!firstExtractor) {
            throw new Error('Cannot remove Loaded Organic tank without extractor stages.');
        }
        const firstStripper = prevState.stages.find(
            (stage: LooseStage) => stage.stageType === STAGE_TYPES.STRIP && stage.location === 1
        );
        if (!firstStripper) {
            throw new Error('Cannot remove Loaded Organic tank without strip stages.');
        }
        const etsWasher = this.getETSWasher();

        const tankStreams = getStreamsForStage(
            terminalLoadedOrganicTank,
            prevState.streams,
            prevState.stages
        );
        const reroutedTankStreams = tankStreams
            .map((stream: LooseStream) => {
                const relatedStages = getStagesForStream(stream, prevState.stages);
                const fromStage = relatedStages.from;
                if (!fromStage) {
                    throw new Error('Tank streams must have a from stage.');
                }
                const toStage = relatedStages.to;
                if (!toStage) {
                    throw new Error('Tank streams must have a to stage.');
                }
                const newStream = {
                    ...stream,
                };
                if (fromStage.stageType === STAGE_TYPES.ORGANIC_TANK) {
                    if (toStage.stageType === STAGE_TYPES.STRIP) {
                        if (!etsWasher) {
                            return null; // this will be covered by the fromStageType === Extract stream.
                        }
                        newStream.from = etsWasher;
                        if (etsWasher.id) {
                            newStream.fromStageId = etsWasher.id;
                        } else {
                            delete newStream.fromStageId;
                        }
                        return newStream;
                    } else if (toStage.stageType === STAGE_TYPES.EXTRACT) {
                        // What to do here? we should technically replace it with a continue stream?
                        return null;
                    } else if (toStage.stageType === STAGE_TYPES.ORGANIC_TANK) {
                        return null;
                    } else {
                        throw new Error(
                            'Unknown to stage type while deleting the terminal LO tank'
                        );
                    }
                } else if (fromStage.stageType === STAGE_TYPES.EXTRACT) {
                    if (toStage.stageType === STAGE_TYPES.ORGANIC_TANK) {
                        newStream.to = firstStripper;
                        if (firstStripper.id) {
                            newStream.toStageId = firstStripper.id;
                        } else {
                            delete newStream.toStageId;
                        }
                        return newStream;
                    } else {
                        throw new Error(
                            'Unknown combination of streams from extract related to terminal LO tank'
                        );
                    }
                } else if (fromStage.stageType === STAGE_TYPES.WASHER) {
                    if (toStage.stageType === STAGE_TYPES.ORGANIC_TANK) {
                        return null; // remove the stream from washer to tank
                    } else {
                        throw new Error(
                            'Unknown combination of streams from extract related to terminal LO tank'
                        );
                    }
                }
                return newStream;
            })
            .filter(Boolean);

        const streamsWithoutTankStreams = prevState.streams.filter(
            (stream: LooseStream) => !tankStreams.includes(stream)
        );
        const stagesWithoutTank = prevState.stages.filter(
            (stage: LooseStage) => stage !== terminalLoadedOrganicTank
        );
        return {
            stages: stagesWithoutTank,
            streams: [...streamsWithoutTankStreams, ...reroutedTankStreams],
        };
    };

    /**
     * Removes an LO tank from the circuit
     * Note: that the tank must be the last tank in the circuit to be deleted
     * Returns the new circuit state
     */
    handleRemoveLoTank = (loTankData: LooseStage, prevState: CircuitState): CircuitState => {
        const mimicCircuit = new MimicCircuit(prevState);
        mimicCircuit.setMode(DIAGRAM_DISPLAY_MODES.SETUP);
        // $FlowIgnore
        const tank: IMimicLoadedOrganicTank = mimicCircuit.getStageByDescription(
            loTankData.stageType,
            loTankData.location
        );

        // ensure the LO tank is the last tank
        // this is a requirement because otherwise, we end up with a discontinuity
        // in the sequence of tank locations. This causes various adverse effects when
        // trying to create new tanks after the deletion.
        const tankCount = mimicCircuit.getTanks().length;
        if (tank.location !== tankCount) {
            this.props.createNewFeedback(
                'ERROR',
                this.props.intl.formatMessage({
                    id: 'feedback.error.cannotDeleteTankItIsNotLast',
                }),
                TOAST_DURATION * 2
            );
            return prevState;
        }

        const tankOutgoingStreams = tank.getOutgoingOrganicStreams();

        const newStreams = [];

        const firstConnectedFromStage = tank.getFirstConnectedFromStage();
        if (firstConnectedFromStage.stageType === STAGE_TYPES.EXTRACT) {
            // We must only reroute the tank if it is connected to the first extractor.
            const previousStage = firstConnectedFromStage.getPreviousPhysicalStage();
            if (!previousStage) {
                // No previous stage, means the extract stage is probably at location 1,
                // lets make sure and connect it to the stripper.
                if (firstConnectedFromStage.location !== 1) {
                    throw new Error(
                        '[LOTank] Trying to remove LO tank coming from an extractor with no previous stage and not at location 1?'
                    );
                }
                // We can safely use the first stripper because terminal LO tanks must go to strip stages.
                const firstStripper = prevState.stages.find(
                    (stage: LooseStage) =>
                        stage.stageType === STAGE_TYPES.STRIP && stage.location === 1
                );
                const newOrganicContinue = {
                    streamType: STREAM_TYPES.CONTINUE,
                    streamCircuit: STREAM_CIRCUITS.ORGANIC,
                    from: firstConnectedFromStage.toJSON(),
                    to: firstStripper,
                };
                if (firstConnectedFromStage.id) {
                    // $FlowIgnore
                    newOrganicContinue.fromStageId = firstConnectedFromStage.id;
                }
                // $FlowIgnore
                if (firstStripper.id) {
                    // $FlowIgnore
                    newOrganicContinue.toStageId = firstStripper.id;
                }
                newStreams.push(newOrganicContinue);
            }
        } else if (firstConnectedFromStage.stageType === STAGE_TYPES.WASHER) {
            // Reroute the `washer->(tank)->(tank to stage)` to `washer->(tank to stage)`
            // When a tank is connected from a washer stage, the first outgoing stream
            // always goes to the first stripper.
            const existingOutgoingStream = tankOutgoingStreams[0];
            const existingContinue = firstConnectedFromStage.getOutgoingOrganicStreams()[0];
            const updatedContinue = {
                ...existingContinue.toJSON(),
                // $FlowIgnore
                to: existingOutgoingStream.toStage.toJSON(),
            };
            // $FlowIgnore
            if (existingOutgoingStream.toStage.id) {
                // $FlowIgnore
                updatedContinue.toStageId = existingOutgoingStream.toStageId;
            }
            newStreams.push(updatedContinue);
        } else {
            throw new Error(
                '[LOTank] Cannot remove LO tank because the stage it is connected from is not extract nor wash...'
            );
        }

        const streamsRelatedToTankStreamsToRemove: Array<IMimicStream> = [];
        const tankIncomingStreams = tank.getIncomingOrganicStreams();
        tankIncomingStreams.forEach((tankIncomingStream: IMimicStream) => {
            tankIncomingStream.siblings.forEach((siblingStream: IMimicStream) => {
                // siblingStream is most likely a bypass feed.
                streamsRelatedToTankStreamsToRemove.push(siblingStream); // add to streams to remove for deletion
                const newOrganicContinueStream = {
                    to: siblingStream.toStage ? siblingStream.toJSON() : null,
                    from: tankIncomingStream.fromStage
                        ? tankIncomingStream.fromStage.toJSON()
                        : null,
                    streamType: STREAM_TYPES.CONTINUE,
                    streamCircuit: STREAM_CIRCUITS.ORGANIC,
                };
                // $FlowIgnore
                if (tankIncomingStream.fromStage.id) {
                    // $FlowIgnore
                    newOrganicContinueStream.fromStageId = tankIncomingStream.fromStage.id;
                }
                // $FlowIgnore
                if (siblingStream.toStage.id) {
                    // $FlowIgnore
                    newOrganicContinueStream.toStageId = siblingStream.toStage.id;
                }
                newStreams.push(newOrganicContinueStream);
            });
        });
        tankOutgoingStreams.forEach((tankOutgoingStream: IMimicStream) => {
            tankOutgoingStream.siblings.forEach((siblingStream: IMimicStream) => {
                if (streamsRelatedToTankStreamsToRemove.indexOf(siblingStream) !== -1) {
                    // the stream was already modified in the `tankIncomingStream` loop above.
                    return; // go to the next sibling stream.
                }
                if (streamsRelatedToTankStreamsToRemove.indexOf(tankOutgoingStream) !== -1) {
                    // the stream was already modified in the `tankIncomingStream` loop above.
                    return; // go to the next sibling stream.
                }
                streamsRelatedToTankStreamsToRemove.push(siblingStream); // add to streams to remove for deletion
                const newOrganicContinueStream = {
                    to: tankOutgoingStream.toStage ? tankOutgoingStream.toStage.toJSON() : null,
                    from: siblingStream.fromStage ? siblingStream.fromStage.toJSON() : null,
                    streamType: STREAM_TYPES.CONTINUE,
                    streamCircuit: STREAM_CIRCUITS.ORGANIC,
                };
                // $FlowIgnore
                if (tankOutgoingStream.toStage.id) {
                    // $FlowIgnore
                    newOrganicContinueStream.toStageId = tankOutgoingStream.toStage.id;
                }
                // $FlowIgnore
                if (siblingStream.fromStage.id) {
                    // $FlowIgnore
                    newOrganicContinueStream.fromStageId = siblingStream.fromStage.id;
                }
                newStreams.push(newOrganicContinueStream);
            });
        });

        const stagesWithoutTank = prevState.stages.filter((stage: LooseStage) => {
            return !(
                stage.stageType === loTankData.stageType && stage.location === loTankData.location
            );
        });
        const streamsWithoutTankStreams = prevState.streams.filter((stream: LooseStream) => {
            const relatedStages = getStagesForStream(stream, prevState.stages);
            const toStage = relatedStages.to;
            const fromStage = relatedStages.from;
            if (!toStage || !fromStage) {
                return true; // keep all streams that don't have a from and to.
            }

            const comesFromOrGoesToLoTank =
                (toStage.stageType === loTankData.stageType &&
                    toStage.location === loTankData.location) ||
                (fromStage.stageType === loTankData.stageType &&
                    fromStage.location === loTankData.location);
            if (comesFromOrGoesToLoTank) {
                return false; // remove all streams that go to or come from the lo tank
            }

            // check if the stream is a stream that is related to an LO tank stream:
            const streamMustBeRemoved = Boolean(
                streamsRelatedToTankStreamsToRemove.find((streamToRemove: IMimicStream) => {
                    if (stream.streamType !== streamToRemove.streamType) {
                        return false;
                    }
                    if (stream.streamCircuit !== streamToRemove.streamCircuit) {
                        return false;
                    }
                    if (!streamToRemove.toStage || !streamToRemove.fromStage) {
                        throw new Error(
                            '[LOTank] Loaded organic tank streams must have a to and from stage.'
                        );
                    }
                    if (toStage.stageType !== streamToRemove.toStage.stageType) {
                        return false;
                    }
                    if (toStage.location !== streamToRemove.toStage.location) {
                        return false;
                    }
                    if (fromStage.stageType !== streamToRemove.fromStage.stageType) {
                        return false;
                    }
                    if (fromStage.location !== streamToRemove.fromStage.location) {
                        return false;
                    }
                    // We can safely assume it is the same stream, so we must remove it.
                    return true;
                })
            );
            if (streamMustBeRemoved) {
                return false; // stream will be removed.
            }
            return true; // stream must be kept
        });

        return {
            stages: [...stagesWithoutTank],
            streams: [...streamsWithoutTankStreams, ...newStreams],
        };
    };

    handleManuallyRemoveLoTank = (loTankData: LooseStage) => {
        this.setState((prevState: State) => ({
            ...this.handleRemoveLoTank(loTankData, prevState),
            isModified: true,
        }));
    };

    handleSelectReagent = (selectedReagent: ImmutableReagent) =>
        this.setState({
            selectedReagent,
            selectedOxime: null,
            isModified: true,
        });

    handleSelectOxime = (selectedOxime: ImmutableOxime) =>
        this.setState({
            selectedOxime,
            selectedReagent: null,
            isModified: true,
        });

    handleSelectMetal = (selectedMetal: ImmutableMetal) =>
        this.setState({
            selectedMetal,
            isModified: true,
        });

    handleSelectCircuitUnit = (selectedCircuitUnit: UnitsConstant) =>
        this.setState({
            selectedCircuitUnit,
            isModified: true,
        });

    handleSelectProductionUnit = (selectedProductionUnit: ProductionUnitsConstant) =>
        this.setState({
            selectedProductionUnit,
            isModified: true,
        });

    /**
     * Handles the footer's save button click.
     */
    handleSaveClicked = () =>
        this.setState({
            modal: {
                modalType: 'SAVE',
            },
        });

    /**
     * Handles the footer's save as button click.
     */
    handleSaveAsClicked = () =>
        this.setState({
            modal: {
                modalType: 'SAVEAS',
            },
        });

    /**
     * Handles the footer's elevate to SolvExtract circuit button click.
     *
     * 1) Validation in model:
     *     - The circuit must have at least 1 extract stage and 1 stripping stage
     *     - The circuit must be configured with a reagent instead of MDR data.
     * 2) [TODO] Save circuit
     * 2) Redirect user
     */
    handleElevateClicked = () => {
        const requiredMessageIds = [];

        if (!this.getNumberOfExtractors() || !this.getNumberOfStrippers()) {
            requiredMessageIds.push('missingStages');
        }

        if (!this.state.selectedReagent && !this.state.selectedOxime) {
            requiredMessageIds.push('missingReagent');
        }

        if (requiredMessageIds.length) {
            this.setState({
                modal: {
                    modalType: 'ELEVATE',
                    modalState: requiredMessageIds,
                },
            });
        } else {
            this.props.onElevateToSolvExtractCircuit(this.props.circuitId);
        }
    };

    /**
     * Handles the save as modal's confirm button
     */
    handleSaveAsConfirm = (name: string, plantName: string, comments: string) => {
        this.props.createAdvancedCircuit(
            {
                name,
                plantName,
                comments,
                ...this.getSaveAsCircuitData(),
            },
            true
        );

        // Disable the save button since it was saved
        this.setState({
            isModified: false,
        });
    };

    /**
     * Handles the save modal's confirm button
     *
     * When a response object is passed into legacyMappers in request.js, it is passed by reference. Due to the flow of the request/response,
     * ORGANIC_TANK was not being replaced by ORGANIC_TANK (legacy value) when the component updated after the form submission.
     * By using lodash's deepClone, the object is no longer the same as the original allowing for the proper value to be used.
     *
     */
    handleSaveConfirm = () => {
        const cirtcuitData = cloneDeep(this.getSaveCircuitData());
        this.props.updateAdvancedCircuit(this.props.circuitId, cirtcuitData);
        // Disable the save button since it was saved
        this.setState({
            isModified: false,
        });

        //Piwik pro log
        logUserAnalyticsInteraction({
            moduleName: APPLICATION_TYPES.MINCHEM,
            circuit: this.state.selectedCircuit.get('name'),
            plant: this.state.selectedCircuit.get('plantName'),
            userName: getLoginUserName(this.props.user),
        });
    };

    /**
     * Hides the currently opened save as modal.
     */
    handleHideModal = () => this.setState({ modal: null });

    /**
     * Opens the modal to edit the mimic diagram of modalType
     */
    handleOpenStreamSelectModal = (streamData: LooseStream) =>
        this.setState({
            modal: {
                modalType: 'STREAM_SELECT',
                modalState: streamData,
            },
        });

    /**
     * Handle advanced stream selection on aqueous stream (Feed, Blend, Bleed, Blend and Bleed or skip with selected unit)
     */
    handleAddAqueousStreamConfirm = (
        streamType: StreamSelectTypeConstant,
        location: number,
        selectedSkipUnitLocation?: ?number
    ) => {
        this.setState((prevState: State) => {
            let streams = prevState.streams;

            // Get the continue stream
            const continueStream = streams.find((streamToFind: LooseStream) => {
                if (
                    streamToFind.streamType === STREAM_TYPES.CONTINUE ||
                    streamToFind.streamType === STREAM_TYPES.SKIP
                ) {
                    const relatedStages = getStagesForStream(streamToFind, prevState.stages);
                    return (
                        streamToFind.streamCircuit === STREAM_CIRCUITS.AQUEOUS &&
                        relatedStages.from &&
                        relatedStages.from.location === location
                    );
                }
                return null;
            });

            if (!continueStream) {
                throw new Error('Adding PLS stream option without continue/skip stream?');
            }

            const relatedStages = getStagesForStream(continueStream, prevState.stages);

            // If stream Type is feed, remove continue stream and add a bleed stream
            if (streamType === STREAM_TYPES.FEED) {
                streams = this.deleteStream(continueStream, streams, prevState.stages);
                streams.push({
                    streamType: STREAM_TYPES.BLEED,
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    to: null,
                    toStageId: null,
                    from: relatedStages.from,
                    fromStageId: relatedStages.from && relatedStages.from.id,
                });
                // Add new feed stream
                streams.push({
                    streamType: STREAM_TYPES.FEED,
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    to: relatedStages.to && relatedStages.to,
                    toStageId: relatedStages.to && relatedStages.to.id,
                    from: null,
                    fromStageId: null,
                });
            } else if (streamType === PLS_STREAM_OPTIONS.BLEED_BLEND) {
                // add bleed and blend streams
                const bleedStream = {
                    streamType: STREAM_TYPES.BLEED,
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    to: null,
                    toStageId: null,
                    from: relatedStages.from,
                    fromStageId: relatedStages.from && relatedStages.from.id,
                };
                streams.push(bleedStream);
                const blendStream = {
                    streamType: STREAM_TYPES.BLEND,
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    to: relatedStages.to,
                    toStageId: relatedStages.to && relatedStages.to.id,
                    from: null,
                    fromStageId: null,
                };
                streams.push(blendStream);
            } else if (streamType === STREAM_TYPES.BLEND) {
                // Add new stream
                streams.push({
                    streamType: STREAM_TYPES.BLEND,
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    to: relatedStages.to && relatedStages.to,
                    toStageId: relatedStages.to && relatedStages.to.id,
                    from: null,
                    fromStageId: null,
                });
            } else if (streamType === STREAM_TYPES.BLEED) {
                // Add new stream
                streams.push({
                    streamType: STREAM_TYPES.BLEED,
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    to: null,
                    toStageId: null,
                    from: relatedStages.from,
                    fromStageId: relatedStages.from && relatedStages.from.id,
                });
            } else if (streamType === STREAM_TYPES.SKIP) {
                // Get from and to stage for skip
                const fromStage = prevState.stages.find(
                    (stageToFind: LooseStage) =>
                        stageToFind.stageType === STAGE_TYPES.EXTRACT &&
                        stageToFind.location === selectedSkipUnitLocation
                );
                // Remove continue streams
                streams = this.deleteStream(continueStream, streams, prevState.stages);
                // Remove aqueous (pls) continue stream between skips from stage and next stage
                // or the aqueous bleed stream from the incoming stream stage
                const secondStream = streams.find((streamToFind: LooseStream) => {
                    if (
                        (streamToFind.streamType === STREAM_TYPES.CONTINUE ||
                            streamToFind.streamType === STREAM_TYPES.BLEED) &&
                        streamToFind.streamCircuit === STREAM_CIRCUITS.AQUEOUS
                    ) {
                        const relatedStagesForStream = getStagesForStream(
                            streamToFind,
                            prevState.stages
                        );
                        return relatedStagesForStream.from.location === fromStage.location;
                    } else {
                        return false;
                    }
                });
                streams = this.deleteStream(secondStream, streams, prevState.stages);
                // Add skip stream
                streams.push({
                    streamType: STREAM_TYPES.SKIP,
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    to: relatedStages.to,
                    toStageId: relatedStages.to && relatedStages.to.id,
                    from: fromStage,
                    fromStageId: fromStage && fromStage.id,
                });
                // Add feed to the stage after skips from stage
                const nextOfFromStage = prevState.stages.find(
                    (stageToFind: LooseStage) =>
                        stageToFind.stageType === STAGE_TYPES.EXTRACT &&
                        stageToFind.location === fromStage.location + 1
                );

                // If we created a skip stream from a raffinate bleed stream, we don't need to create a new feed
                if (secondStream.streamType !== STREAM_TYPES.BLEED) {
                    streams.push({
                        streamType: STREAM_TYPES.FEED,
                        streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                        to: nextOfFromStage,
                        toStageId: nextOfFromStage && nextOfFromStage.id,
                        from: null,
                        fromStageId: null,
                    });
                }
                // Add bleed to the stage before skips to stage
                const beforeToStage = prevState.stages.find(
                    (stageToFind: LooseStage) =>
                        stageToFind.stageType === STAGE_TYPES.EXTRACT &&
                        stageToFind.location === relatedStages.to.location - 1
                );
                streams.push({
                    streamType: STREAM_TYPES.BLEED,
                    streamCircuit: STREAM_CIRCUITS.AQUEOUS,
                    to: null,
                    toStageId: null,
                    from: beforeToStage,
                    fromStageId: beforeToStage && beforeToStage.id,
                });
            }

            return {
                streams: [...streams],
                modal: null,
                isModified: true,
            };
        });
    };

    /**
     * Handle advanced stream selection on electrolyte stream (series or parallel advanced stream configuration)
     * In case of PARALLEL configuration selection, removed continue stream and replaces it with bleed and feed stream from related stages.
     * Since series is a default selection, there is no further action to be takes.
     */
    handleAddElectrolyteStreamConfirm = (option: ElectrolyteOptionConstant, location: number) => {
        if (option === ELECTROLYTE_STREAM_OPTIONS.PARALLEL) {
            this.setState((prevState: State) => {
                let streams = prevState.streams;
                // Get the continue stream
                const continueStream = streams.find((streamToFind: LooseStream) => {
                    if (
                        streamToFind.streamType === STREAM_TYPES.CONTINUE &&
                        streamToFind.streamCircuit === STREAM_CIRCUITS.ELECTROLYTE
                    ) {
                        const relatedStages = getStagesForStream(streamToFind, prevState.stages);
                        return relatedStages.from && relatedStages.from.location === location;
                    }
                    return false;
                });
                if (!continueStream) {
                    throw new Error('Adding electrolyte stream option without continue stream?');
                }
                // Get stages between which this continue stream is placed
                const relatedStages = getStagesForStream(continueStream, prevState.stages);

                // Remove continue stream
                streams = this.deleteStream(continueStream, streams, prevState.stages);

                // Add feed and bleed streams between these two stages
                const bleedStream = {
                    streamType: STREAM_TYPES.BLEED,
                    streamCircuit: STREAM_CIRCUITS.ELECTROLYTE,
                    to: null,
                    toStageId: null,
                    from: relatedStages.from,
                    fromStageId: continueStream.fromStageId,
                };
                // Add new bleed stream
                streams.push(bleedStream);
                const feedStream = {
                    streamType: STREAM_TYPES.FEED,
                    streamCircuit: STREAM_CIRCUITS.ELECTROLYTE,
                    to: relatedStages.to,
                    toStageId: continueStream.toStageId,
                    from: null,
                    fromStageId: null,
                };
                // Add new feed stream
                streams.push(feedStream);

                return {
                    streams,
                    modal: null,
                    isModified: true,
                };
            });
        }
    };

    handleAddOrganicInExtractStreamConfirm = (
        selectedOption: OrganicInExtractOptionConstant,
        location: number,
        tankOptions: Object | null
    ) => {
        const fromStageType = STAGE_TYPES.EXTRACT;

        this.setState((prevState: State) => {
            let streams = prevState.streams;
            const stages = prevState.stages;

            // Get the continue stream
            const organicContinueStream = streams.find((streamToFind: LooseStream) => {
                if (
                    streamToFind.streamType === STREAM_TYPES.CONTINUE &&
                    streamToFind.streamCircuit === STREAM_CIRCUITS.ORGANIC
                ) {
                    const relatedStages = getStagesForStream(streamToFind, stages);
                    return (
                        relatedStages.from &&
                        relatedStages.from.location === location &&
                        relatedStages.from.stageType === fromStageType
                    );
                }
                return false;
            });
            if (!organicContinueStream) {
                throw new Error('Adding organic stream option without continue stream?');
            }
            const firstStripStage = stages.find(
                (stage: LooseStage) => stage.stageType === STAGE_TYPES.STRIP && stage.location === 1
            );

            const etsWasher = this.getETSWasher();
            const terminalLOTank = this.getTerminalLoadedOrganicTank();

            // The order of these is important.
            // The terminal Bypass Bleed must first
            // enter the ets washer, then the terminal LO tank and finally the first strip stage.
            const terminalBypassBleedStage = etsWasher || terminalLOTank || firstStripStage;

            // Get stages between which this continue stream is placed
            const relatedStages = getStagesForStream(organicContinueStream, stages);
            const toStage = relatedStages.to;
            const fromStage = relatedStages.from;
            if (!fromStage) {
                throw new Error(
                    'Adding organic stream option without a from stage is not possible.'
                );
            }

            let isFromTank = false;
            let isToTank = false;
            let toTank = null;
            let fromTank = null;
            const mimicCircuit = new MimicCircuit({
                streams,
                stages,
            });
            mimicCircuit.setMode(DIAGRAM_DISPLAY_MODES.SETUP);
            const loTanks = this.getLoadedOrganicTanks();
            const tankCount = loTanks.length;
            // Find all the LO tanks that are before the connected extractor to the selected tank (new or existing tank)
            const previousLOTanks = loTanks.filter((stage: LooseStage) => {
                // $FlowIgnore
                const tank: IMimicLoadedOrganicTank = mimicCircuit.getStageByDescription(
                    stage.stageType,
                    stage.location
                );
                const connectedExtractor = tank.getFirstConnectedFromStage();
                return (
                    connectedExtractor.stageType === STAGE_TYPES.EXTRACT &&
                    connectedExtractor.location <= fromStage.location
                );
            });
            // We must sort the tanks based on their extractor's locations.
            // Tanks connected to the right most extractors are first regardless of the tank's location
            const sortedLOTanks = previousLOTanks.sort((stageA: LooseStage, stageB: LooseStage) => {
                // $FlowIgnore
                const tankA: IMimicLoadedOrganicTank = mimicCircuit.getStageByDescription(
                    stageA.stageType,
                    stageA.location
                );
                // $FlowIgnore
                const tankB: IMimicLoadedOrganicTank = mimicCircuit.getStageByDescription(
                    stageB.stageType,
                    stageB.location
                );
                const connectedExtractorA = tankA.getFirstConnectedFromStage();
                const connectedExtractorB = tankB.getFirstConnectedFromStage();
                return connectedExtractorA.location - connectedExtractorB.location;
            });
            const previousLOTank = sortedLOTanks.pop();

            // Change the to/from stages if we have a tank option selected during bypassing.
            if (tankOptions) {
                let isToNewTank = false;
                let isFromNewTank = false;
                let newTank = null;
                if (tankOptions.toTank) {
                    isToTank = true;

                    isToNewTank = tankOptions.toTank === NEW_TANK_OPTION;
                    if (isToNewTank) {
                        const newTankLocation = tankCount + 1;
                        newTank = {
                            stageType: STAGE_TYPES.ORGANIC_TANK,
                            location: newTankLocation,
                        };
                        stages.push(newTank);
                        toTank = newTank;
                    } else {
                        toTank = tankOptions.toTank.toJSON();
                    }
                }
                if (tankOptions.fromTank) {
                    isFromTank = true;
                    isFromNewTank = tankOptions.fromTank === NEW_TANK_OPTION;
                    if (isFromNewTank) {
                        fromTank = newTank;
                    } else {
                        fromTank = tankOptions.fromTank.toJSON();
                    }
                }
                if (
                    tankOptions.isAlsoToPreviousLoadedOrganicTank ||
                    (isToTank && isToNewTank && !isFromNewTank)
                ) {
                    // If we created a new to tank, but we did not set the `from tank`
                    // then the new to tank needs to have a bypass bleed to the previous LO tank or first stripper
                    const bypassBleedStreamToTank = etsWasher || previousLOTank || firstStripStage;
                    const bypassBleedStream = {
                        streamType: STREAM_TYPES.BYPASS_BLEED,
                        streamCircuit: STREAM_CIRCUITS.ORGANIC,
                        to: bypassBleedStreamToTank,
                        from: newTank,
                    };
                    if (bypassBleedStreamToTank && bypassBleedStreamToTank.id) {
                        bypassBleedStream.toStageId = bypassBleedStreamToTank.id;
                    }
                    streams.push(bypassBleedStream);
                }
            }

            // Add the Bypass Bleed stream:
            if (selectedOption === ORGANIC_IN_EXTRACT_STREAM_OPTIONS.BYPASS) {
                // Remove continue stream
                streams = this.deleteStream(organicContinueStream, streams, stages);

                let bypassBleedToStage = toStage;
                const bypassBleedFromStage = relatedStages.from;

                if (isToTank && toTank !== terminalLOTank) {
                    bypassBleedToStage = toTank;
                } else {
                    bypassBleedToStage = terminalBypassBleedStage;
                }

                const bypassBleedStream = {
                    streamType: STREAM_TYPES.BYPASS_BLEED,
                    streamCircuit: STREAM_CIRCUITS.ORGANIC,
                    to: bypassBleedToStage,
                    from: bypassBleedFromStage,
                };
                if (bypassBleedFromStage && bypassBleedFromStage.id) {
                    bypassBleedStream.fromStageId = bypassBleedFromStage.id;
                }
                if (bypassBleedToStage && bypassBleedToStage.id) {
                    bypassBleedStream.toStageId = bypassBleedToStage.id;
                }
                streams.push(bypassBleedStream);
            }

            const bypassInToStage = toStage;
            let bypassInFromStage = fromStage;
            if (isFromTank) {
                bypassInFromStage = fromTank;
            } else {
                // If we do not come from a tank, then we come from the last strip stage
                // Until we implement Barren organic tanks.
                const lastStripStage = stages.find(
                    (stage: LooseStage) =>
                        stage.stageType === STAGE_TYPES.STRIP &&
                        stage.location === this.getNumberOfStrippers()
                );
                const steWasher = this.getSTEWasher();
                bypassInFromStage = steWasher || lastStripStage;
            }

            const bypassInStreamType =
                selectedOption === ORGANIC_IN_EXTRACT_STREAM_OPTIONS.BYPASS
                    ? STREAM_TYPES.BYPASS_FEED
                    : STREAM_TYPES.BYPASS_BLEND;

            const bypassInStream = {
                streamType: bypassInStreamType,
                streamCircuit: STREAM_CIRCUITS.ORGANIC,
                to: toStage,
                from: bypassInFromStage,
            };
            if (bypassInToStage && bypassInToStage.id) {
                bypassInStream.toStageId = bypassInToStage.id;
            }
            if (bypassInFromStage && bypassInFromStage.id) {
                bypassInStream.fromStageId = bypassInFromStage.id;
            }
            streams.push(bypassInStream);

            return {
                streams: [...streams], // this spread solves the issue of not updating streams when we only add a stream(in case of organic blend)
                stages: [...stages],
                modal: null,
                isModified: true,
            };
        });
    };

    handleAddOrganicInStripStreamConfirm = (
        selectedOption: OrganicInStripOptionConstant,
        location: number,
        tankOptions?: Object | null
    ) => {
        const fromStageType = STAGE_TYPES.STRIP;

        this.setState((prevState: State) => {
            let streams = prevState.streams;

            // Get the continue stream
            const continueStream = streams.find((streamToFind: LooseStream) => {
                if (
                    streamToFind.streamType === STREAM_TYPES.CONTINUE &&
                    streamToFind.streamCircuit === STREAM_CIRCUITS.ORGANIC
                ) {
                    const relatedStages = getStagesForStream(streamToFind, prevState.stages);
                    return (
                        relatedStages.from &&
                        relatedStages.from.location === location &&
                        relatedStages.from.stageType === fromStageType
                    );
                }
                return false;
            });
            if (!continueStream) {
                throw new Error('Adding organic stream option without continue stream?');
            }
            // Get stages between which this continue stream is placed
            const relatedStages = getStagesForStream(continueStream, prevState.stages);
            // Remove continue stream
            streams = this.deleteStream(continueStream, streams, prevState.stages);

            // Add stream from the first extractor or the terminal Lo tank
            const terminalLOTank = this.getTerminalLoadedOrganicTank();
            const etsWasher = this.getETSWasher();
            const firstExtractStage = prevState.stages.find(
                (stage: LooseStage) =>
                    stage.stageType === STAGE_TYPES.EXTRACT && stage.location === 1
            );
            const bypassFeedFromStage = terminalLOTank || etsWasher || firstExtractStage;
            if (!bypassFeedFromStage) {
                throw new Error('Could not find bypass feed from stage for strip bypass.');
            }

            const bypassFeedToStage = prevState.stages.find(
                (stage: LooseStage) =>
                    stage.stageType === STAGE_TYPES.STRIP && stage.location === location + 1
            );
            if (!bypassFeedToStage) {
                throw new Error('Could not find bypass feed to stage for strip bypass.');
            }
            const bypassFeedStream = {
                streamType: STREAM_TYPES.BYPASS_FEED,
                streamCircuit: STREAM_CIRCUITS.ORGANIC,
                to: bypassFeedToStage,
                from: bypassFeedFromStage,
            };
            if (bypassFeedToStage.id) {
                bypassFeedStream.toStageId = bypassFeedToStage.id;
            }
            if (bypassFeedFromStage.id) {
                bypassFeedStream.fromStageId = bypassFeedFromStage.id;
            }

            streams.push(bypassFeedStream);

            const lastExtractStage = prevState.stages.find(
                (stage: LooseStage) =>
                    stage.stageType === STAGE_TYPES.EXTRACT &&
                    stage.location === this.getNumberOfExtractors()
            );
            const steWasher = this.getSTEWasher();
            const bypassBleedToStage = steWasher || lastExtractStage;
            const bypassBleedStream = {
                streamType: STREAM_TYPES.BYPASS_BLEED,
                streamCircuit: STREAM_CIRCUITS.ORGANIC,
                to: bypassBleedToStage,
                toStageId: bypassBleedToStage && bypassBleedToStage.id,
                from: relatedStages.from,
                fromStageId: relatedStages.from && relatedStages.from.id,
            };
            streams.push(bypassBleedStream);

            // When a user adds an organic bypass in stripping, we must make the aqueous section Parallel:
            // Find the continue stream that connects the bypassFeedToStage and the bypassBleedFromStage
            const stripContinueStream = streams.find(
                (stream: LooseStream) =>
                    // The stream we want is an ELECTROLYTE CONTINUE stream.
                    // However the relatedStages is based on the ORGANIC stream.
                    // Therefore relatedStages.from is the stream.to and relatedStages.to is the stream.from
                    // Make sure it is an electrolyte continue stream.
                    stream.streamType === STREAM_TYPES.CONTINUE &&
                    stream.streamCircuit === STREAM_CIRCUITS.ELECTROLYTE &&
                    ((stream.toStageId &&
                        stream.toStageId === (relatedStages.from && relatedStages.from.id)) ||
                        (stream.to &&
                            relatedStages.from &&
                            (stream.to.stageType === relatedStages.from.stageType &&
                                stream.to.location === relatedStages.from.location))) &&
                    ((stream.fromStageId && stream.fromStageId === bypassFeedToStage.id) ||
                        (stream.from &&
                            (stream.from.stageType === bypassFeedToStage.stageType &&
                                stream.from.location === bypassFeedToStage.location)))
            );
            if (stripContinueStream) {
                streams = this.deleteStream(stripContinueStream, streams, prevState.stages);
                const newFeed = {
                    streamType: STREAM_TYPES.FEED,
                    streamCircuit: STREAM_CIRCUITS.ELECTROLYTE,
                    to: relatedStages.from,
                    toStageId: relatedStages.from && relatedStages.from.id,
                    from: null,
                    fromStageId: null,
                };
                const newBleed = {
                    streamType: STREAM_TYPES.BLEED,
                    streamCircuit: STREAM_CIRCUITS.ELECTROLYTE,
                    to: null,
                    toStageId: null,
                    from: bypassFeedToStage,
                    fromStageId: bypassFeedToStage.id,
                };
                streams.push(newFeed, newBleed);
            }

            return {
                streams: [...streams], // this spread solves the issue of not updating streams when we only add a stream(in case of organic blend)
                modal: null,
                isModified: true,
            };
        });
    };

    /**
     * Handle advanced stream selection on organic stream (bypass in extraction section, bypass in stripping section and blend in extraction section)
     */
    handleAddOrganicStreamConfirm = (
        selectedOption: OrganicInExtractOptionConstant | OrganicInStripOptionConstant,
        location: number,
        isExtractionSection: boolean,
        tankOptions?: Object | null = null
    ) => {
        if (isExtractionSection) {
            this.handleAddOrganicInExtractStreamConfirm(selectedOption, location, tankOptions);
        } else {
            // $FlowIgnore
            this.handleAddOrganicInStripStreamConfirm(selectedOption, location);
        }
    };

    redirectToCircuitElevationPage = () =>
        this.props.history.push(
            `${NAVIGATION_ROUTES.CIRCUIT}${this.props.circuitId}${NAVIGATION_ROUTES.ELEVATE}`
        );

    /**
     * Renders the save as modal.
     */
    renderSaveAsModal = () => (
        <CircuitDetailsModal
            title={this.props.intl.formatMessage({
                id: 'components.CircuitModals.SaveAsModal.title',
            })}
            confirmButtonText={this.props.intl.formatMessage({
                id: 'components.CircuitModals.SaveAsModal.confirmButton',
            })}
            errors={this.props.circuitErrors}
            loading={this.props.isCreatingCircuit}
            onConfirm={this.handleSaveAsConfirm}
            onCancel={this.handleHideModal}
        />
    );

    renderSaveModal = () => (
        <ConfirmationModal
            title={this.props.intl.formatMessage({
                id: 'components.CircuitModals.SaveModal.title',
            })}
            areYouSureStart={this.props.intl.formatMessage({
                id: 'components.CircuitModals.SaveModal.confirmationText',
            })}
            confirmButtonText={this.props.intl.formatMessage({
                id: 'components.CircuitModals.SaveModal.confirmButton',
            })}
            errors={this.props.circuitErrors}
            loading={this.props.isUpdatingCircuit}
            onConfirm={this.handleSaveConfirm}
            onCancel={this.handleHideModal}
        />
    );

    /**
     * Renders the stream type select modal
     */
    renderElevateCircuitModal = () => {
        if (
            !this.state.modal ||
            !this.state.modal.modalState ||
            (this.state.modal &&
                this.state.modal.modelState &&
                !this.state.modal.modelState.isArray()) ||
            !this.state.modal.modalType === 'ELEVATE'
        ) {
            return null;
        }

        return (
            <AlertModal
                title={this.props.intl.formatMessage({
                    id: 'containers.MimicDiagramContainer.ElevateCircuitModal.title',
                })}
                alertText={this.props.intl.formatMessage({
                    id: `containers.MimicDiagramContainer.ElevateCircuitModal.alerts.${
                        this.state.modal.modalState[0]
                    }`,
                })}
                acknowledgeButtonText={this.props.intl.formatMessage({
                    id: 'containers.MimicDiagramContainer.ElevateCircuitModal.alertButton',
                })}
                onAcknowledgement={this.handleHideModal}
            />
        );
    };

    /**
     * Renders the stream type select modal
     */
    renderStreamSelectModal = () => {
        if (
            !this.state.modal ||
            !this.state.modal.modalState ||
            !this.state.modal.modalType === 'STREAM_SELECT'
        ) {
            return null;
        }

        // We can assume that from stage type is === to stage type because the render stream select modal
        // can only be triggered from an edit button on a continue stream. This continue stream must come from and go to the same stage type.

        // $FlowIgnore
        const streamData: LooseStream = this.state.modal.modalState;

        const fromStage = this.state.stages.find((stage: LooseStage) => {
            if (streamData.fromStageId) {
                return stage.id === streamData.fromStageId;
            } else {
                return (
                    stage.stageType === streamData.from.stageType &&
                    stage.location === streamData.from.location
                );
            }
        });
        if (!fromStage) {
            throw new Error('Tried to open stream select modal with no fromStage data...');
        }

        const stageLocation = fromStage.location;

        switch (streamData.streamCircuit) {
            case STREAM_CIRCUITS.AQUEOUS: {
                if (streamData.streamType === STREAM_TYPES.SKIP) {
                    return (
                        <PLSSkipStreamSelectModal
                            currentLocation={stageLocation}
                            onConfirm={this.handleAddAqueousStreamConfirm}
                            onCancel={this.handleHideModal}
                        />
                    );
                } else {
                    const mimicCircuit = new MimicCircuit(this.state);
                    mimicCircuit.setMode(DIAGRAM_DISPLAY_MODES.SETUP);
                    let toStage;
                    if (streamData.toStageId) {
                        toStage = mimicCircuit.getStageById(streamData.toStageId);
                    } else {
                        toStage = mimicCircuit.getStageByDescription(
                            // $FlowIgnore
                            streamData.to.stageType,
                            // $FlowIgnore
                            streamData.to.location
                        );
                    }
                    if (!toStage) {
                        throw new Error(
                            'Tried to open PLS stream select modal with no toStage found.'
                        );
                    }
                    const stages = SkipStream.getAllPossibleStagesFrom(toStage);
                    const stageLocations = stages.map((stage: IMimicStage) => stage.location);
                    return (
                        <AqueousStreamSelectModal
                            currentLocation={stageLocation}
                            stagesForSkipLocations={stageLocations}
                            onConfirm={this.handleAddAqueousStreamConfirm}
                            onCancel={this.handleHideModal}
                        />
                    );
                }
            }
            case STREAM_CIRCUITS.ELECTROLYTE: {
                return (
                    <ElectrolyteStreamSelectModal
                        currentLocation={stageLocation}
                        onConfirm={this.handleAddElectrolyteStreamConfirm}
                        onCancel={this.handleHideModal}
                    />
                );
            }
            case STREAM_CIRCUITS.ORGANIC: {
                // NOTE: for now it is impossible to open the
                // organic modal without being on an organic continue stream
                // therefore we can make the assumption that we will have both a
                // from stage as well as a to stage.
                // This is why there are FlowIgnores

                // $FlowIgnore
                const toStage: LooseStage = this.state.stages.find((stage: LooseStage) => {
                    if (streamData.toStageId) {
                        return stage.id === streamData.toStageId;
                    } else {
                        return (
                            stage.stageType === streamData.to.stageType &&
                            stage.location === streamData.to.location
                        );
                    }
                });
                const mimicCircuit = new MimicCircuit(this.state);
                mimicCircuit.setMode(DIAGRAM_DISPLAY_MODES.SETUP);
                // $FlowIgnore
                const mimicStream: IContinueStream = mimicCircuit.streams.find(
                    (stream: IMimicStream) => {
                        // $FlowIgnore
                        const mimicFromStage: IMimicStage = stream.fromStage;
                        // $FlowIgnore
                        const mimicToStage: IMimicStage = stream.toStage;
                        return (
                            streamData.streamCircuit === stream.streamCircuit &&
                            streamData.streamType === stream.streamType &&
                            fromStage.location === mimicFromStage.location &&
                            fromStage.stageType === mimicFromStage.stageType &&
                            toStage.location === mimicToStage.location &&
                            toStage.stageType === mimicToStage.stageType
                        );
                    }
                );
                if (!mimicStream) {
                    throw new Error(
                        'Could not find the organic continue stream in the Mimic Engine...'
                    );
                }
                if (fromStage.stageType === STAGE_TYPES.EXTRACT) {
                    return (
                        <OrganicInExtractStreamSelectModal
                            mimicCircuit={mimicCircuit}
                            mimicStream={mimicStream}
                            currentLocation={stageLocation}
                            onConfirm={this.handleAddOrganicStreamConfirm}
                            onCancel={this.handleHideModal}
                        />
                    );
                } else {
                    return (
                        <OrganicInStripStreamSelectModal
                            mimicCircuit={mimicCircuit}
                            mimicStream={mimicStream}
                            currentLocation={stageLocation}
                            onConfirm={this.handleAddOrganicStreamConfirm}
                            onCancel={this.handleHideModal}
                        />
                    );
                }
            }
            default:
                throw new Error('Unknown modal for stream circuit in Circuit Setup');
        }
    };

    /**
     * Renders the modals to edit the mimic diagram
     */
    renderModals = () => {
        if (!this.state.modal) return null;

        switch (this.state.modal.modalType) {
            case 'STREAM_SELECT':
                return this.renderStreamSelectModal();
            case 'SAVE':
                return this.renderSaveModal();
            case 'SAVEAS':
                return this.renderSaveAsModal();
            case 'ELEVATE':
                return this.renderElevateCircuitModal();
            default:
                throw new Error('Unknown modal type in Circuit Setup');
        }
    };

    /**
     * Renders the primary content (the mimic diagram)
     */
    renderMimicDiagram = () => (
        <React.Fragment>
            <PreventNavigationPrompt shouldBlock={this.state.isModified} />
            <MimicDiagramErrorBoundary>
                <HeaderOverflowContainer>
                    <MimicDiagram
                        displayMode={DIAGRAM_DISPLAY_MODES.SETUP}
                        stages={this.state.stages}
                        streams={this.state.streams}
                        loadingCircuit={
                            this.props.loadingCircuit || this.props.fetchingSingleCircuit
                        }
                        onOpenModal={this.handleOpenStreamSelectModal}
                        onChangeStageProperties={this.handleChangeStageProperties}
                        onRemoveAdvancedStreamValueClicked={
                            this.handleRemoveAdvancedStreamValueClicked
                        }
                        onRemoveLoTank={this.handleManuallyRemoveLoTank}
                    />
                    <OverflowEnd>{this.renderFooter()}</OverflowEnd>
                </HeaderOverflowContainer>
            </MimicDiagramErrorBoundary>
            {this.renderModals()}
        </React.Fragment>
    );

    /**
     * Renders the footer
     */
    renderFooter = () => {
        const circuitIsNotValid = !this.isValidCircuit();
        return (
            <CircuitSetupFooter
                circuitId={this.props.circuitId}
                elevateDisabled={circuitIsNotValid || this.state.isModified}
                onElevateClicked={this.handleElevateClicked}
                onSaveAsClicked={this.handleSaveAsClicked}
                onSaveClicked={this.handleSaveClicked}
                saveAsDisabled={circuitIsNotValid}
                saveDisabled={circuitIsNotValid || !this.state.isModified}
                userIsAdmin={isSysAdmin(this.props.user)}
            />
        );
    };

    /**
     * Renders the sidebar content used by the React UI Library's SidebarLayout
     */
    renderSidebar = () => (
        <CircuitSetupSidebar
            metals={this.props.metals}
            loadingCircuit={this.props.loadingCircuit || this.props.fetchingSingleCircuit}
            extractStageCount={this.getNumberOfExtractors()}
            stripStageCount={this.getNumberOfStrippers()}
            washerETSStageActive={this.hasETSWasher()}
            washerSTEStageActive={this.hasSTEWasher()}
            loadedOrganicTankActive={this.hasTerminalLoadedOrganicTankIsActive()}
            selectedMetal={this.state.selectedMetal}
            selectedCircuitUnit={this.state.selectedCircuitUnit}
            selectedProductionUnit={this.state.selectedProductionUnit}
            selectedReagent={this.state.selectedReagent}
            selectedOxime={this.state.selectedOxime}
            isothermStoichiometryFactor={this.state.isothermStoichiometryFactor}
            loadingMetals={this.props.loadingMetals}
            onStageCountChange={this.handleStageCountChange}
            onWashActiveChange={this.handleWashActiveChange}
            onTankChange={this.handleTankChange}
            onSelectReagent={this.handleSelectReagent}
            onSelectOxime={this.handleSelectOxime}
            onSelectMetal={this.handleSelectMetal}
            onSelectCircuitUnit={this.handleSelectCircuitUnit}
            onSelectProductionUnit={this.handleSelectProductionUnit}
            onChangeIsothermStoichiometryFactor={this.handleChangeIsothermStoichiometryFactor}
            user={this.props.user}
        />
    );

    render() {
        return (
            <SidebarLayout
                styles={{
                    sidebar: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                    },

                    main: {
                        height: `calc(100vh - ${STYLE_VALUES.HEADER.HEIGHT})`,
                        overflowY: 'hidden',
                    },
                }}
                renderMain={this.renderMimicDiagram}
                renderSidebar={this.renderSidebar}
                sidebarWidth={STYLE_VALUES.SIDEBAR.WIDTH}
                collapsible
                flush
                mainFlush
            />
        );
    }
}

const mapStateToProps = (state: State, ownProps: Props) =>
    createStructuredSelector({
        minChemCircuitQuery: selectMinChemCircuitQuery(),
        loadingCircuit: selectMinChemIsQueryingStatus(),
        fetchingSingleCircuit: selectCircuitIsFetchingStatus(),

        isCreatingCircuit: selectCircuitsAreCreating(),
        isUpdatingCircuit: selectCircuitsAreUpdating(),
        circuitErrors: selectCircuitErrors(),

        reagents: selectAllReagents(),
        oximes: selectAllOximes(),
        loadingReagents: selectReagentsAreFetching(),
        loadingOximes: selectOximesAreFetching(),

        metals: selectAllMetals(),
        loadingMetals: selectMetalsAreFetching(),

        user: selectUser(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchAllReagents,
            fetchAllMetals,

            fetchCircuit,
            createAdvancedCircuit,
            updateAdvancedCircuit,

            createNewFeedback,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(MimicDiagramContainer))
);
