// @flow strict

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { MicroFrontEnds } from 'env';

import MicroFrontend from '..';
import { ContainerCentered, LayoutContainer } from 'styles/common';
import ErrorMessage from 'components/ErrorMessage';

import type { IntlType } from 'types';

import type { ImmutableUser } from 'services/Authentication/types';
import { selectUser } from 'services/Authentication/selectors';
import { canAccessCyanex } from 'utils/authentication';

type Props = {
    intl: IntlType,
    user: ImmutableUser,
};

type State = {
    isUnLoadable: boolean,
};

class Cyanex extends React.PureComponent<Props, State> {
    state = {
        isUnLoadable: false,
    };

    static getHost = () => {
        return MicroFrontEnds.cyanex; // name must match the secret object's structure in GCP
    };

    static getUniqueName = () => {
        return 'CyanexMFE'; // name must match the container name in the MFE code
    };

    getMountProps = () => {
        return {
            locale: this.props.intl.locale.toUpperCase(),
        };
    };

    onError = () => {
        this.setState({ isUnLoadable: true });
    };

    render() {
        // Keeping this commented until we are ready to deploy Cyanex to SAM users.
        const isAllowed = canAccessCyanex(this.props.user);
        if (!isAllowed) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: 'views.CyanexMFE.userMustBeAdminOrSAM',
                        })}
                    />
                </ContainerCentered>
            );
        }
        if (this.state.isUnLoadable) {
            return (
                <ContainerCentered>
                    <ErrorMessage
                        errorMessage={this.props.intl.formatMessage({
                            id: 'views.CyanexMFE.anErrorOccurredDuringLoad',
                        })}
                    />
                </ContainerCentered>
            );
        }
        return (
            <MicroFrontend
                name={Cyanex.getUniqueName()}
                host={Cyanex.getHost()}
                mountedMicroFrontendProps={this.getMountProps()}
                onError={this.onError}
                mfeIsInFullScreen
            />
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        user: selectUser(),
    });

export default connect(mapStateToProps)(injectIntl(Cyanex));
